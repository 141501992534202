import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ndpr from "../assets/img/OUTCESSNDPC.jpg";

const DataPolicy = () => {
  return (
    <div id="iso-quality">
      <Navbar />
      <div className="iso-quality-intro">
        <div className="iso-quality-intro-container">
          <div className="iso-quality-intro-content">
            <h1>
              DATA PROTECTION AND <span>PRIVACY POLICY STATEMENT</span>
            </h1>
          </div>
        </div>
      </div>

      <main>
        <section data-aos="fade-right">
          <div className="ndpr-logo">
            <img src={ndpr} alt="ndpr-logo" />
          </div>
        </section>
        <section className="quality-policy-statement" data-aos="fade-left">
          <div className="document-outline">
            <div className="introduction">
              <h1>
                <u>Introduction</u>
              </h1>
              <p>
                Outcess Data Protection and Privacy Policy is in line with the
                laws set out in the NDPR (Nigeria Data Protection Regulation)
                and covers the following areas in layman's terms:
              </p>
              <ol>
                <li>What data we have and why we have it.</li>
                <li>How we manage and encrypt our data.</li>
                <li>Who is responsible for our data.</li>
                <li>How we train our team.</li>
                <li>What we will do if the worst happens.</li>
              </ol>
            </div>
            <div className="reasons">
              <ol>
                <li className="reasons-hoghlight">
                  <p className="reason-highlight-heading">
                    <u>What data we have and why we have it.</u>
                  </p>
                  <p>
                    Outcess Solutions Nigeria Limited needs to collect and use
                    certain information about the people we meet to carry out
                    our work and serve our clients to the best of our ability.
                    This personal information is collected and dealt with as set
                    out in the NDPR. The data we collect and store is:
                  </p>
                  <ul className="reasons-list">
                    <li>
                      First and last name so that we can address you personally
                      in our communications and so that we can differentiate who
                      is who using last names.
                    </li>
                    <li>Email address</li>
                    <li>Physical address</li>
                    <li>Telephone Numbers</li>
                  </ul>
                </li>

                <li className="reasons-hoghlight">
                  <p className="reason-highlight-heading">
                    <u>Data Controller.</u>
                  </p>
                  <p>
                    Outcess Solutions Nigeria Limited is the Data Controller
                    under the NDPR, which means that it determines what purposes
                    personal information held and will be used for. It is also
                    responsible for notifying the Information Commissioner of
                    the data it holds or is likely to hold, and the general
                    purposes that this data will be used for.
                  </p>
                </li>
                <li className="reasons-hoghlight">
                  <p className="reason-highlight-heading">
                    <u>Disclosure.</u>
                  </p>
                  <p>
                    Outcess Solutions Nigeria Limited does not share data with
                    other agencies save for when required to do so under
                    statute; e.g. HMRC and so on. Outcess Solutions Nigeria
                    Limited intends to ensure that personal information is
                    treated lawfully and correctly. To this end, Outcess
                    Solutions Nigeria Limited will adhere to the Principles of
                    Data Protection, as detailed in the NDPR which was issued on
                    25th January 2019 in pursuant to section 6 (a) and (c) of
                    the National Information Technology Development Agency Act
                    2007 (the ‘NITDA Act’). Specifically, the Principles require
                    that personal information:
                  </p>
                  <ul className="reasons-list-two">
                    <li>
                      Shall be processed fairly and lawfully and, in particular,
                      shall not be processed unless specific conditions are met,
                    </li>
                    <li>
                      Shall be obtained only for one or more of the purposes
                      specified in the Act, and shall not be processed in any
                      manner incompatible with that purpose or those purposes,
                    </li>
                    <li>
                      Shall be adequate, relevant and not excessive in relation
                      to those purpose(s)
                    </li>
                    <li>
                      Shall be accurate and, where necessary, kept up to date,
                    </li>
                    <li>Shall not be kept for longer than is necessary</li>
                    <li>
                      Shall be processed in accordance with the rights of data
                      subjects under the Act,
                    </li>
                    <li>
                      Shall be kept secure by the Data Controller who takes
                      appropriate technical and other measures to prevent
                      unauthorized or unlawful processing or accidental loss or
                      destruction of, or damage to, personal information,
                    </li>
                    <li>
                      Shall not be transferred to a country or territory outside
                      the Nigeria.
                    </li>
                  </ul>
                  <p>
                    Outcess Solutions Nigeria Limited will, through appropriate
                    management and strict application of criteria and controls:
                  </p>
                  <ul className="reason-list-three">
                    <li>
                      Observe fully conditions regarding the fair collection and
                      use of information.
                    </li>
                    <li>
                      Meet its legal obligations to specify the purposes for
                      which information is used.
                    </li>
                    <li>
                      Collect and process appropriate information, and only to
                      the extent that it is needed to fulfil its operational
                      needs or to comply with any legal requirements.
                    </li>
                    <li>Ensure the quality of information used.</li>
                    <li>
                      Ensure that the rights of people about whom information is
                      held, can be fully exercised under the Act. These include:
                      <ul className="reason-list-four">
                        <li>
                          The right to be informed that processing is being
                          undertaken,
                        </li>
                        <li>
                          The right of access to one’s personal information,
                        </li>
                        <li>
                          The right to prevent processing in certain
                          circumstances,
                        </li>
                        <li>
                          The right to correct, rectify, block or erase
                          information which is regarded as wrong information.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Take appropriate technical and organisational security
                      measures to safeguard personal information.
                    </li>
                    <li>
                      Treat people justly and fairly whatever their age,
                      religion, disability, gender, sexual orientation or
                      ethnicity when dealing with requests for information.
                    </li>
                    <li>
                      Set out clear procedures for responding to requests for
                      information.
                    </li>
                  </ul>
                </li>
                <li className="reasons-hoghlight">
                  <p className="reason-highlight-heading">
                    <u>Data collection</u>
                  </p>
                  <p>Informed consent is when:</p>
                  <ul className="reason-list-three">
                    <li>
                      An Individual/Service User clearly understands why their
                      information is needed, who it will be shared with, the
                      possible consequences of them agreeing or refusing the
                      proposed use of the data.
                    </li>
                    <li>And then gives their consent.</li>
                  </ul>
                  <p>
                    Outcess Solutions Nigeria Limited will ensure that data is
                    collected within the boundaries defined in this policy. This
                    applies to data that is collected in person, or by
                    completing a form. When collecting data, Outcess Solutions
                    Nigeria Limited will ensure that the Employee/Service
                    User/Other Individual:
                  </p>
                  <ul className="reason-list-five">
                    <li>
                      Clearly understands why the information is needed. B.
                    </li>
                    <li>
                      Understands what it will be used for and what the
                      consequences are should the Individual/Service User decide
                      not to give consent to processing.
                    </li>
                    <li>
                      As far as reasonably possible, grants explicit consent,
                      either written or verbal for data to be processed.
                    </li>
                    <li>
                      Is, as far as reasonably practicable, competent enough to
                      give consent and has given so freely without any duress.
                    </li>
                    <li>
                      Has received sufficient information on why their data is
                      needed and how it will be used.
                    </li>
                  </ul>
                </li>
                <li className="reasons-hoghlight">
                  <p className="reason-highlight-heading">
                    <u>Data Storage</u>
                  </p>
                  <p>
                    Information and records relating to service users will be
                    stored securely and will only be accessible to authorized
                    staff. Information will be stored for only as long as it is
                    needed under the required statute and will be disposed of
                    appropriately.{" "}
                  </p>
                  <p>
                    It is Outcess Solutions Nigeria Limited’s responsibility to
                    ensure all personal and company data is nonrecoverable from
                    any computer system previously used within the organisation,
                    which has been passed on/sold to a third party.
                  </p>
                </li>
                <li className="reasons-hoghlight">
                  <p className="reason-highlight-heading">
                    <u>Data access and accuracy</u>
                  </p>
                  <p>
                    All Employee/Service Users/Other Individuals have the right
                    to access the information Outcess Solutions Nigeria Limited
                    holds about them. Outcess Solutions Nigeria Limited will
                    also take reasonable steps to ensure that this information
                    is kept up to date by asking data subjects whether there
                    have been any changes.
                  </p>
                  <p>
                    In addition, Outcess Solutions Nigeria Limited will ensure
                    that:
                  </p>
                  <ul className="reason-list-three">
                    <li>
                      It has a Data Protection Officer with specific
                      responsibility for ensuring compliance with Data
                      Protection.
                    </li>
                    <li>
                      Everyone processing personal information understands that
                      they are contractually responsible for following good data
                      protection practice.
                    </li>
                    <li>
                      Everyone processing personal information is appropriately
                      trained to do so.
                    </li>
                    <li>
                      Everyone processing personal information is appropriately
                      supervised.
                    </li>
                    <li>
                      Anybody wanting to make enquiries about handling personal
                      information knows what to do.
                    </li>
                    <li>
                      It deals promptly and courteously with any enquiries about
                      handling personal information.
                    </li>
                    <li>
                      It describes clearly how it handles personal information.
                    </li>
                    <li>
                      It will regularly review and audit the ways it holds,
                      manage and use personal information.
                    </li>
                    <li>
                      It regularly assesses and evaluates its methods and
                      performance in relation to handling personal information.{" "}
                    </li>
                    <li>
                      All staff are aware that a breach of the rules and
                      procedures identified in this policy may lead to
                      disciplinary action being taken against them.
                    </li>
                  </ul>
                  <p>
                    This policy will be updated as necessary to reflect best
                    practice in data management, security and control and to
                    ensure compliance with any changes or amendments made to the
                    Nigeria Data Protection Regulation 2019.
                  </p>

                  <p>
                    In case of any queries or questions in relation to this
                    policy please contact the Outcess Solutions Nigeria Limited
                    Data Protection Officer.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default DataPolicy;
