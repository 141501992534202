import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
    return (
        <div id='Home-404'>
            <div className="mars"></div>
            <img src="https://assets.codepen.io/1538474/404.svg" className="logo-404" alt='logo' />
            <img src="https://assets.codepen.io/1538474/meteor.svg" className="meteor" alt='logo' />
            <p className="title-mete">Oh no!!</p>
            <p className="subtitle">
                You’re either misspelling the URL <br /> or requesting a page that's no longer here.
            </p>
            <div align="center">
                <NavLink to="/" className="btn-back" exact>Back to home page</NavLink>
            </div>
            <img src="https://assets.codepen.io/1538474/astronaut.svg" className="astronaut" alt='logo' />
            <img src="https://assets.codepen.io/1538474/spaceship.svg" className="spaceship" alt='logo' />

        </div>
    )
}

export default NotFound;