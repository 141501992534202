import {
    CASE_STUDY_DATA_CREATE_REQUEST, CASE_STUDY_DATA_CREATE_SUCCESS,
    CASE_STUDY_DATA_CREATE_FAIL, CASE_STUDY_DATA_CREATE_RESET
} from '../constants/caseStudiesConstants'

export const caseStudysReducer = (state = {}, action) => {
    switch (action.type) {
        case CASE_STUDY_DATA_CREATE_REQUEST:
            return { loading: true }
        case CASE_STUDY_DATA_CREATE_SUCCESS:
            return { loading: false, success: true, data: action.payload }
        case CASE_STUDY_DATA_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case CASE_STUDY_DATA_CREATE_RESET:
            return {}
        default:
            return state
    }
}