import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const Analytics = () => {

  const [isVisible, setVisibility] = useState(false);
  const [entered, setEntered] = useState(false);

  const onChange = visiblity => {
    setVisibility(visiblity);
  };

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible]);


  return (
    <div className="tcss-trends">
      <div className="tcss-trends-container">
        <div className="tcss-trend-content">
          <div className="tcss-trend" data-aos="fade-up" data-aos-delay="100">
            <h1>
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }} onChange={onChange}>
                <div style={{ height: 100 }}>
                  {entered ? <CountUp end={21600000} duration={5} separator="," suffix="+" /> : null}

                  <h6 id="Visibility">CUSTOMER INTERACTIONS YEARLY</h6>
                </div>

              </VisibilitySensor></h1>
          </div>
          <div className="tcss-trend" data-aos="fade-up" data-aos-delay="300">

            <h1>
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                <div style={{ height: 100 }}>
                  {entered ? <CountUp end={3000} duration={5} separator="," suffix="+" /> : null}

                  <h6 id="Visibility">CRM SEATS DEPLOYED SUCCESSFULLY</h6>
                </div>
              </VisibilitySensor></h1>
          </div>
          <div className="tcss-trend" data-aos="fade-up" data-aos-delay="600">
            <h1>
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                <div style={{ height: 100 }}>
                  {entered ? <CountUp end={201} duration={5} separator="," /> : null}

                  <h6 id="Visibility">SKILLED EXPERTS</h6>
                </div>
              </VisibilitySensor></h1>
          </div>
          <div className="tcss-trend" data-aos="fade-up" data-aos-delay="900">
            <h1>
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                <div style={{ height: 100 }}>
                  {entered ? <CountUp end={4000} duration={5} separator="," suffix="+" /> : null}

                  <h6 id="Visibility">CONTACT CENTER SEATS SUCCESSFULLY DEPLOYED</h6>
                </div>
              </VisibilitySensor></h1>

          </div>
          <div className="content" />


        </div>
      </div>
    </div>
  )
}

export default Analytics;