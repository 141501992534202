import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhoneSquare,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { NavLink } from "react-router-dom";
import RFQModal from "./RFQModal";

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const currentYear = new Date().getFullYear();

  return (
    <div id="footer">
      <RFQModal show={show} setShow={setShow} />
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-grid-up">
            <div className="footer-details">
              <ul>
                <li>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <a
                    href="https://www.google.com/maps/place/75+Opebi+Rd,+Opebi+101233,+Ikeja/@6.5881754,3.3602254,17z/data=!3m1!4b1!4m5!3m4!1s0x103b926a76c393a9:0xeb56c08a8639c34a!8m2!3d6.5881754!4d3.3624141"
                    target="_blank"
                    rel="noreferrer"
                  >
                    75 Opebi Road, Ikeja, Lagos State
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <a href="mailto:info@outcess.com">info@outcess.com</a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhoneSquare} />
                  <a href="tel:02013300308">02013300308</a>
                </li>
                {/* <li>
                  <FontAwesomeIcon icon={faGlobe} />
                  <a href="https://www.outcess.com" target="_blank" rel="noreferrer" >www.outcess.com</a>
                </li> */}
              </ul>
            </div>
            <div className="footer-details">
              <p>COMPANY</p>
              <ul>
                <li>
                  <NavLink to="/about" activeClassName="active-link" exact>
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/business-partners"
                    activeClassName="active-link"
                    exact
                  >
                    Business Partners
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ideology" activeClassName="active-link" exact>
                    Ideology
                  </NavLink>
                </li>
                 <li>
                  <NavLink
                    to="/IsoQualityStatement"
                    activeClassName="active-link"
                    exact
                  >
                    ISO Quality Statement
                  </NavLink>
                </li>
               
                <li>
                  <NavLink
                    to="/outcess-clients"
                    activeClassName="active-link"
                    exact
                  >
                    Clients
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/careers" activeClassName="active-link" exact>
                    Careers
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-policy"
                    activeClassName="active-link"
                    exact
                  >
                   Privacy Policy 
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer-details">
              <p>SERVICES</p>
              <ul>
                <li>
                  <NavLink
                    to="/business-process-outsourcing"
                    activeClassName="active-link"
                    exact
                  >
                    Business Process Outsourcing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/business-productivity-solutions"
                    activeClassName="active-link"
                    exact
                  >
                    Business Productivity Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/cloud-solutions"
                    activeClassName="active-link"
                    exact
                  >
                    Cloud Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/customer-engagement-Solutions"
                    activeClassName="active-link"
                    exact
                  >
                    Customer Engagement Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/omni-channel-marketing"
                    activeClassName="active-link"
                    exact
                  >
                    Omni-Channel Digital Marketing
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer-details">
              <p>SUPPORT</p>
              <ul>
                <li>
                  <p className="contact-us" onClick={handleShow}>
                    Contact Us
                  </p>
                </li>
                <li>
                  <a href="https://outcessblog.lll-ll.com/">Blog</a>
                </li>
                <li>
                  <NavLink to="/faqs" activeClassName="active-link" exact>
                    FAQ
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-media">
            <p>
              <FontAwesomeIcon icon={faCopyright} /> {currentYear} Outcess. All
              Rights Reserved
            </p>
            <ul>
              <li>
                <a
                  href="https://twitter.com/outcesspowered"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li>
                <a
                  href="https://facebook.com/outcessng"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/outcess_ng"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a
                  href="http://linkedin.com/company/outcess/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
