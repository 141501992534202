import React from 'react'

const LoaderPlus = () => {
   return (
      <div className="loader-plus">
         <div className="loader"></div>
      </div>
   )
}

export default LoaderPlus
