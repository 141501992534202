import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createJobApplication } from "../actions/jobApplicantsAction";
// import { getAllCareerPostAction } from '../actions/jobApplicantsAction';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Modal } from "react-bootstrap";
import Logo from "../assets/img/outcess-logo.png";
import LoaderPlus from "../components/LoaderPlus";
import { JOB_APPLICANTS_CREATE_RESET } from "../constants/jobApplicantsConstants";
import { baseUrl } from "../shared/baseUrl";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import emailjs from "emailjs-com";

const CurrentOpenings = ({ history }) => {
  const dispatch = useDispatch();
  const form = useRef();

  const createJobApp = useSelector((state) => state.createJobApp);
  const {
    success: successCreate,
    error: errorCreate,
    loading: loadingCreate,
  } = createJobApp;

  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [educationalQualification, setEducationalQualification] = useState("");
  const [nyscStatus, setNyscStatus] = useState("");
  const [operatingWindow, setOperatingWindow] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const [resumeURL, setResumeUrl] = useState("");
  const [igboLanguage, setIgboLanguage] = useState(false);
  const [hausaLanguage, setHausaLanguage] = useState(false);
  const [yorubaLanguage, setYorubaLanguage] = useState(false);
  const [englishLanguage, setEnglishLanguage] = useState(false);
  const [frenchLanguage, setFrenchLanguage] = useState(false);
  const [errorCreate1, seterrorCreate1] = useState(false);
  const [errorCreate2, seterrorCreate2] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(baseUrl + "/api/v1/career");
        setData(data.careers);
        setLoading(false);
      } catch (error) {
        setErrorMsg(error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (successCreate === true) {
      emailjs
        .sendForm(
          "service_czlj7hs",
          "template_6biyk9r",
          form.current,
          "LSzAkP5R6YerQQpXk"
        )
        .then(
          (result) => console.log(result.text),
          (error) => console.log(error.text)
        );
      setShow(false);
      history.push("/current-openings");
      dispatch({
        type: JOB_APPLICANTS_CREATE_RESET,
      });
      setFullName("");
      setEmail("");
      setGender("");
      setAge("");
      setPhoneNumber("");
      setAddress("");
      setJobLocation("");
      setEducationalQualification("");
      setNyscStatus("");
      setOperatingWindow("");
      setYearsOfExperience(0);
      setResumeUrl("");
      setIgboLanguage(false);
      setHausaLanguage(false);
      setEnglishLanguage(false);
      setFrenchLanguage(false);
    }
  }, [dispatch, successCreate, history]);

  const submitHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      createJobApplication(
        fullname,
        email,
        gender,
        age,
        phoneNumber,
        address,
        jobLocation,
        educationalQualification,
        nyscStatus,
        operatingWindow,
        yearsOfExperience,
        resumeURL,
        igboLanguage,
        hausaLanguage,
        yorubaLanguage,
        englishLanguage,
        frenchLanguage
      )
    );
  };

  const handleShow = (breakpoint) => {
    setFullscreen(breakpoint);
    setShow(true);
  };

  useEffect(() => {
    if (errorCreate) {
      seterrorCreate1(true);
    }
    setTimeout(() => {
      seterrorCreate1(false);
    }, 1000);
  }, [errorCreate]);

  if (errorCreate1) {
    swal({
      title: `${errorCreate}`,
      icon: "error",
      button: "Close",
    });
  }

  useEffect(() => {
    if (errorMsg) {
      seterrorCreate2(true);
    }
    setTimeout(() => {
      seterrorCreate2(false);
    }, 1000);
  }, [errorMsg]);

  if (errorCreate2) {
    swal({
      title: `${errorMsg}`,
      icon: "error",
      button: "Close",
    });
  }
  if (successCreate) {
    swal({
      title: "Your Application was Successfully!",
      icon: "success",
      button: "Close",
    });
  }

  const [message, setMessage] = useState();

  useEffect(() => {
    setMessage([
      ` Fullname :${fullname}

Email :${email}

Age :${age}

Gender :${gender}

PhoneNumber :${phoneNumber}

Address :${address}

Job Location :${jobLocation}

Educational Qualification :${educationalQualification}

Nysc Status :${nyscStatus}

Operating Window :${operatingWindow}

Years Of Experience :${yearsOfExperience}

Resume URL :${resumeURL}

  Languages :- Igbo-Language :${igboLanguage}  
  Hausa-Language :${hausaLanguage} 
  Yoruba-Language :${yorubaLanguage} 
  English-Language :${englishLanguage} 
  French-Language :${frenchLanguage}`,
    ]);
  }, [
    fullname,
    email,
    gender,
    age,
    phoneNumber,
    address,
    jobLocation,
    educationalQualification,
    nyscStatus,
    operatingWindow,
    yearsOfExperience,
    resumeURL,
    igboLanguage,
    hausaLanguage,
    yorubaLanguage,
    englishLanguage,
    frenchLanguage,
  ]);
  return (
    <div>
      <Navbar />
      <div className="current-page-img">
        <div></div>
      </div>
      <main>
        <div>
          <h1 className="current-header" data-aos="fade-down">
            Available <span id="openings-colour"> Openings</span>
          </h1>
        </div>
        <div>
          <br />
        </div>

        {loading && <LoaderPlus />}

        <div className="faq-question-container">
          {data.map((data, index) => (
            <div key={index}>
              <div className="faq-question">
                <input id={data._id} type="checkbox" className="panel" />
                <div className="plus">+</div>
                <label htmlFor={data._id} className="panel-title">
                  {" "}
                  <h5>{data.jobTitle}</h5>
                </label>
                <p id="panel-content-sub">
                  Location: {data.jobLocation}{" "}
                  <span className="panel-date">
                    {" "}
                    Date: {moment(data.createdAt).format("DD-MM-YYYY")}{" "}
                  </span>{" "}
                </p>
                <div id="panel-content-h5" className="panel-content">
                  <br />
                  <h5>Job Overview</h5>
                  <p>{data.jobOverview}</p>
                  <br />
                  <h5>Responsibilities</h5>
                  <p>{data.responsilities}</p>
                  <br />
                  <h5>Qualifications </h5>
                  <p>{data.qualifications}</p>
                  <br />
                  <h5>Benefits</h5>
                  <p>{data.benefits}</p>
                  <br />

                  <div>
                    {values.map((v, idx) => (
                      <button
                        key={idx}
                        className="me-2  ccsnl-btn"
                        onClick={() => handleShow(v)}>
                        Apply Now
                        {typeof v === "string" && `below ${v.split("-")[0]}`}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <>
            <Modal
              show={show}
              fullscreen={fullscreen}
              onHide={() => setShow(false)}
              style={{ overflow: "hidden" }}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <img src={Logo} className="job-image" alt="Outcess LTD" />{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-fullscreen modal-body">
                <div id="body-job-app">
                  <div>
                    <div id="main-container">
                      <header>
                        <h1 id="title">Job form</h1>
                        <p id="description">Fill in to apply</p>
                      </header>
                      <form
                        id="form-container"
                        ref={form}
                        onSubmit={submitHandler}>
                        <div className="question-container">
                          <label className="question-label" for="dropdown">
                            Job Location:{" "}
                          </label>
                          <select
                            id="jobLocation"
                            className="row-input"
                            required
                            value={jobLocation}
                            onChange={(e) => setJobLocation(e.target.value)}>
                            <option selected>Select an option</option>
                            <option value="Lagos">Lagos</option>
                            <option value="Enugu">Enugu</option>
                          </select>
                        </div>
                        <div className="question-container">
                          <label
                            name="from_name"
                            id="from_name"
                            className="question-label"
                            for="name">
                            Full Name:{" "}
                          </label>
                          <input
                            id="name"
                            className="row-input"
                            type="text"
                            placeholder="Enter your name"
                            required
                            value={fullname}
                            onChange={(e) => setFullName(e.target.value)}
                          />

                          <textarea
                            style={{ display: "none" }}
                            name="message"
                            id="message"
                            className="row-input"
                            type="text"
                            rows={5}
                            required
                            defaultValue={message}></textarea>
                        </div>

                        <div className="question-container">
                          <label
                            id="email-label"
                            className="question-label"
                            for="email">
                            Email Address:{" "}
                          </label>
                          <input
                            id="email"
                            className="row-input"
                            type="email"
                            placeholder="Enter your email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="question-container">
                          <label className="question-label" for="dropdown">
                            Gender:{" "}
                          </label>
                          <select
                            id="dropdown"
                            className="row-input"
                            required
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}>
                            <option selected>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                        <div className="question-container">
                          <label
                            id="email-label"
                            className="question-label"
                            for="Age">
                            Age:{" "}
                          </label>
                          <input
                            id="Age"
                            className="row-input"
                            type="number"
                            placeholder="Enter your Age"
                            required
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                          />
                        </div>
                        <div className="question-container">
                          <label
                            id="email-label"
                            className="question-label"
                            for="Age">
                            Mobile Number:{" "}
                          </label>
                          <input
                            id="Age"
                            className="row-input"
                            type="number"
                            placeholder="Enter Mobile Number"
                            required
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                        <div className="question-container">
                          <label
                            id="email-label"
                            className="question-label"
                            for="Age">
                            Area of Residence:{" "}
                          </label>
                          <textarea
                            id="Textarea"
                            className="row-input"
                            type="text"
                            placeholder="Enter Area of Residence"
                            rows={5}
                            required
                            value={address}
                            onChange={(e) =>
                              setAddress(e.target.value)
                            }></textarea>
                        </div>
                        <div className="question-container">
                          <label className="question-label" for="dropdown">
                            Educational Qualification:
                          </label>
                          <select
                            id="dropdown"
                            className="row-input"
                            required
                            value={educationalQualification}
                            onChange={(e) =>
                              setEducationalQualification(e.target.value)
                            }>
                            <option value="">select Qualification</option>
                            <option value="BSC">B.Sc</option>
                            <option value="MSC">M.Sc</option>
                            <option value="HND">HND</option>
                            <option value="OND">OND</option>
                            <option value="SSCE">SSCE</option>
                          </select>
                        </div>
                        <div className="question-container">
                          <label
                            id="email-label"
                            className="question-label"
                            for="Age">
                            NYSC Status:
                          </label>
                          <select
                            id="dropdown"
                            className="row-input"
                            value={nyscStatus}
                            onChange={(e) => setNyscStatus(e.target.value)}>
                            <option value="select">Select NYSC Status</option>
                            <option value="completed">Completed</option>
                            <option value="currently serving">
                              Currently Serving
                            </option>
                            <option value="not served">Not Served</option>
                          </select>
                        </div>
                        <div className="question-container">
                          <label
                            id="email-label"
                            className="question-label"
                            for="Window">
                            Operation Window:
                          </label>
                          <select
                            id="dropdown"
                            className="row-input"
                            value={operatingWindow}
                            onChange={(e) =>
                              setOperatingWindow(e.target.value)
                            }>
                            <option value="">Select Operation Window:</option>
                            <option value="Inbound [8:00am-3:30pm]">
                              Inbound [8:00am-3:30pm]
                            </option>
                            <option value="Inbound [1:00pm-9:00pm]">
                              Inbound [1:00pm-9:00pm]
                            </option>
                            <option value="Inbound [8:30pm-8:30am]">
                              Inbound [8:30pm-8:30am]
                            </option>
                            <option value="Outbound [8:00am-5:00pm]">
                              Outbound [8:00am-5:00pm]
                            </option>
                            <option value="Recovery [8:00am-5:00pm]">
                              Recovery [8:00am-5:00pm]
                            </option>
                          </select>
                        </div>
                        <div className="question-container">
                          <label
                            id="number-label"
                            className="question-label"
                            for="number">
                            Years of experience:
                          </label>
                          <input
                            id="number"
                            className="row-input"
                            type="number"
                            placeholder="Enter years of experience"
                            min="0"
                            max="50"
                            value={yearsOfExperience}
                            onChange={(e) =>
                              setYearsOfExperience(e.target.value)
                            }
                          />
                        </div>
                        <div className="question-container">
                          <label
                            id="number-label"
                            className="question-label"
                            for="number">
                            Resume ~ Cv:
                          </label>
                          <input
                            id="Resume"
                            className="row-input"
                            type="text"
                            placeholder="Enter link to your ~ CV"
                            min="0"
                            max="50"
                            value={resumeURL}
                            onChange={(e) => setResumeUrl(e.target.value)}
                          />
                        </div>

                        <div className="question-container">
                          <p className="question-label">Spoken Languages:</p>

                          <div className="option-group">
                            <label className="option-container" for="box-Igbo">
                              <input
                                type="checkbox"
                                id="box-Igbo"
                                name="checkbox"
                                value="Igbo"
                                checked={igboLanguage}
                                onChange={(e) =>
                                  setIgboLanguage(e.target.checked)
                                }
                              />
                              <span className="option-label">Igbo</span>
                            </label>

                            <label className="option-container" for="box-Hausa">
                              <input
                                type="checkbox"
                                id="box-Hausa"
                                name="checkbox"
                                value="Hausa"
                                checked={hausaLanguage}
                                onChange={(e) =>
                                  setHausaLanguage(e.target.checked)
                                }
                              />
                              <span className="option-label">Hausa</span>
                            </label>

                            <label
                              className="option-container"
                              for="box-Yoruba">
                              <input
                                type="checkbox"
                                id="box-Yoruba"
                                name="checkbox"
                                value="Yoruba"
                                checked={yorubaLanguage}
                                onChange={(e) =>
                                  setYorubaLanguage(e.target.checked)
                                }
                              />
                              <span className="option-label">Yoruba</span>
                            </label>

                            <label
                              className="option-container"
                              for="box-French">
                              <input
                                type="checkbox"
                                id="box-French"
                                name="checkbox"
                                value="French"
                                checked={frenchLanguage}
                                onChange={(e) =>
                                  setFrenchLanguage(e.target.checked)
                                }
                              />
                              <span className="option-label">French</span>
                            </label>

                            <label
                              className="option-container"
                              for="box-English">
                              <input
                                type="checkbox"
                                id="box-English"
                                name="checkbox"
                                value="English"
                                checked={englishLanguage}
                                onChange={(e) =>
                                  setEnglishLanguage(e.target.checked)
                                }
                              />
                              <span className="option-label">English</span>
                            </label>
                          </div>
                        </div>
                        <button
                          id="submit"
                          disabled={loadingCreate && true}
                          type="submit"
                          value="Register">
                          {loadingCreate ? "submiting..." : "submit"}
                        </button>
                      </form>
                    </div>
                    <main></main>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CurrentOpenings;
