import React from "react";
import { Carousel } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFunnelDollar,
  faChartLine,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Slide1 from "../assets/img/Landing1.jpeg";
import Slide2 from "../assets/img/omni-m.jpeg";
import Slide3 from "../assets/img/fromhome.jpeg";
import Slide5 from "../assets/img/Slide5.jpg";
import Slide7 from "../assets/img/Slide7.jpeg";

const LandingCarousel = () => {
  return (
    <div id="landing-carousel">
      <Carousel fade>

      <Carousel.Item>
          <img className="d-block w-100" src={Slide1} alt="First slide" />
          <Carousel.Caption>
            <div className="caption-details">
              <h1>
                Transform your Customer Engagement with AI-Powered Solutions
              </h1>
              <NavLink to="/business-process-outsourcing" exact>
                <button>Learn more</button>
              </NavLink>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={Slide1} alt="First slide" />
          <Carousel.Caption>
            <div className="caption-details">
              <h1>
                Digital Marketing that delivers customer engagement at every
                touch point in real life
              </h1>
              <NavLink to="/customer-engagement-Solutions" exact>
                <button>Learn more</button>
              </NavLink>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={Slide2} alt="Second slide" />
          <Carousel.Caption>
            <div className="caption-details">
              <h1>
                Increase Customer satisfaction by 55% and issue resolution by
                90% with our contact center
              </h1>
              <NavLink to="/customer-engagement-Solutions" exact>
                <button>Learn more</button>
              </NavLink>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={Slide3} alt="Third slide" />
          <Carousel.Caption>
            <div className="caption-details">
              <h1>
                Set up a remote contact center in 3 days. Get your contact
                center work from home ready.
              </h1>
              <NavLink to="/be-work-from-home-ready" exact>
                <button>Learn more</button>
              </NavLink>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={Slide5} alt="fourth slide" />
          <Carousel.Caption>
            <div className="caption-details">
              <h1>
                Accelerate your time to success based on repeatable methodology
                with cloud solutions.
              </h1>
              <NavLink to="/cloud-solutions" exact>
                <button>Learn more</button>
              </NavLink>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slide7} alt="sixth slide" />
          <Carousel.Caption>
            <div className="caption-details">
              <h1>Reduce Overhead cost With our BPO Services.</h1>
              <NavLink to="/case-studies" exact>
                <button>Learn more</button>
              </NavLink>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="intro-cards" data-aos="fade-up">
        <div className="intro-cards-container">
          <div className="intro-card">
            <div className="intro-icon">
              <FontAwesomeIcon icon={faFunnelDollar} />
            </div>
            <h5>Enhanced Loan Recovery</h5>
          </div>
          <div className="intro-card">
            <div className="intro-icon">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <h5>Business Productivity Tools</h5>
          </div>
          <div className="intro-card">
            <div className="intro-icon">
              <FontAwesomeIcon icon={faUsers} />
            </div>
            <h5>Omni Channel Solutions</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingCarousel;
