import { JOB_APPLICANTS_CREATE_REQUEST, JOB_APPLICANTS_CREATE_SUCCESS, JOB_APPLICANTS_CREATE_FAIL, JOB_APPLICANTS_CREATE_RESET, GET_ALL_JOB_POST_REQUEST, GET_ALL_JOB_POST_SUCCESS, GET_ALL_JOB_POST_FAIL, GET_ALL_JOB_POST_RESET, } from '../constants/jobApplicantsConstants'


export const createJobApplicationReducer = (state = {}, action) => {
 switch (action.type) {
  case JOB_APPLICANTS_CREATE_REQUEST:
   return { loading: true }
  case JOB_APPLICANTS_CREATE_SUCCESS:
   return { loading: false, success: true }
  case JOB_APPLICANTS_CREATE_FAIL:
   return { loading: false, error: action.payload }
  case JOB_APPLICANTS_CREATE_RESET:
   return {}
  default:
   return state
 }
}


export const getAllJobPostReducer = (state = {}, action) => {
 switch (action.type) {
  case GET_ALL_JOB_POST_REQUEST:
   return { loading: true }
  case GET_ALL_JOB_POST_SUCCESS:
   return { loading: false, success: true, data: action.payload }
  case GET_ALL_JOB_POST_FAIL:
   return { loading: false, error: action.payload }
  case GET_ALL_JOB_POST_RESET:
   return {}
  default:
   return state
 }
}




