import React from 'react';
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import { NavLink } from 'react-router-dom';

const Careers = () => {
  return (
    <div id="careers">
      <Navbar />
      <div className="careers-intro">
        <div className="crrs-intr-cntnr">
          <div className="crrs-intr-cntnt">
            <h1>Outcess <span>Careers</span></h1>
            <h4>If you’re passionate, intellectually curious and have a knack for doing big things, you will like it here.</h4>
            <NavLink to="/current-openings" activeClassName="active-link" exact><button className="ccsnl-btn">See current openings </button></NavLink>
          </div>
        </div>
      </div>
      <main>
        <section className="crrs-core">
          <h3 data-aos="fade-down">Everything you need, to do your best <span>work</span></h3>
          <div className="crrs-three-core" data-aos="fade-up" data-aos-delay="100">
            <div className="crrs-core-card">
              <div className="crrs-core-img1" />
              <h5>Team-building experiences</h5>
            </div>
            <div className="crrs-core-card" data-aos="fade-up" data-aos-delay="400">
              <div className="crrs-core-img2" />
              <h5>Value creation</h5>
            </div>
            <div className="crrs-core-card" data-aos="fade-up" data-aos-delay="700">
              <div className="crrs-core-img3" />
              <h5>Collaborative spaces</h5>
            </div>
          </div>
        </section>
        <section className="othr-crr-aspcts seg1">
          <div className="other-crr-aspcts-img cs1"data-aos="fade-right" />
          <div className="other-crr-aspcts-info">
            <h5> Outsourcing made easy</h5>
            <p className="other-crr-p"> Our people are our most valuable assets, and this shows in our support for one another.  Our goal of becoming the best outsourcing company for customers by delivering memorable experiences has become an essential part of our staff’s everyday work.</p>
          </div>
        </section>
        <section className="othr-crr-aspcts seg2">
          <div className="other-crr-aspcts-info">
            <h5>Value-creation made easy</h5>
            <p className="other-crr-p">Take a ramen-making class or a canoeing lesson to connect with your co-workers outside of the office.</p>
          </div>
          <div className="other-crr-aspcts-img cs2"  data-aos="fade-left"/>
        </section>
        <section className="othr-crr-aspcts seg3">
          <div className="other-crr-aspcts-img cs3" data-aos="fade-right"/>
          <div className="other-crr-aspcts-info">
            <h5>Collaboration made easy</h5>
            <p className="other-crr-p">Need to get off site for the day? Collaborate with your team in a workspace designed to give you a fresh perspective.</p>
          </div>
        </section>
        <section className="othr-crr-aspcts seg4">
          <div className="other-crr-aspcts-info">
            <h5>Customer-service made easy</h5>
            <p className="other-crr-p">‘’Specialized agents are available to help in 5 languages, 7 days/week during business hours’’</p>
          </div>
          <div className="other-crr-aspcts-img cs4"  data-aos="fade-left"/>
        </section>
      </main>
      <Footer />
    </div>
  )
}

export default Careers;