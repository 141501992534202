import React from 'react';
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faChess } from '@fortawesome/free-solid-svg-icons';

const Ideology = () => {
  return (
    <div id="ideology">
      <Navbar />
      <div className="idlgy-intro">
        <div className="idlgy-intro-container">
          <div className="idlgy-intro-content">
            <h1>Ideology</h1>
          </div>
        </div>
      </div>
      <main>
        <section className="vsn-mssn">
          <div className="vsm-mssn-cntnt" data-aos="fade-right">
            <span>
              <FontAwesomeIcon icon={faChess} />
            </span>
            <h4>Our Mission</h4>
            <div>
              <p> Inspire and empower people, teams and organizations to do more faster leveraging technology</p>
            </div>
          </div>
          <div className="vsm-mssn-cntnt" data-aos="fade-left">
            <span>
              <FontAwesomeIcon icon={faEye} />
            </span>
            <h4>Our Vision</h4>
            <div>
              <p>To become the African innovation leader in the delivery of digital services globally</p>
            </div>
          </div>
        </section>
        <section className="idlgy-others">
          <div className="idlgy-othrs-img1" data-aos="fade-right"></div>
          <div className="idlgy-othrs-info">
            <h4>Customer focus</h4>
            <p>Our purpose of being in business is to serve our customers and exceed their expectations. This means that we organise every aspect of our business around the ever-evolving needs of our customers to ensure that they get unmatched service every time.</p>
          </div>
        </section>
        <section className="idlgy-others">
          <div className="idlgy-othrs-info">
            <h4>People First</h4>
            <p>Our people are our most prized asset. This is not a cliché! We see our people as more than just employees but as unique individuals with an abundance of value creating resources, so we ensure that we create a working environment that caters to them holistically and empowers them to continue to grow and thrive.</p>
          </div>
          <div className="idlgy-othrs-img2" data-aos="fade-left"></div>
        </section>
        <section className="idlgy-others">
          <div className="idlgy-othrs-img3" data-aos="fade-right"></div>
          <div className="idlgy-othrs-info">
            <h4>Passion</h4>
            <p>We love what we do and we do it with spirit. Our culture is defined by passionate and excellence driven people who go the extra mile to solve problems and create value.</p>
          </div>
        </section>
        <section className="idlgy-others">
          <div className="idlgy-othrs-info">
            <h4>Creativity</h4>
            <p>We believe in imagination and curiosity, and we value that diversity of perspectives that everyone brings. It is this mind set that continuously drives us to seek out new, different and better ways of getting things done.</p>
          </div>
          <div className="idlgy-othrs-img4" data-aos="fade-left"></div>
        </section>
        <section className="idlgy-others">
          <div className="idlgy-othrs-img5" data-aos="fade-right"></div>
          <div className="idlgy-othrs-info">
            <h4>Integrity</h4>
            <p>We believe that integrity goes beyond honesty. It is authenticity. There is nothing pretentious about us. All our intentions, actions and interactions with each other, our clients, our partners and our community is undergirded by this sense of authenticity, and this is how we build trust. You can count on us. We keep our word.</p>
          </div>
        </section>
        <section className="idlgy-others">
          <div className="idlgy-othrs-info">
            <h4>Collaboration</h4>
            <p>We understand that isolation is counter-productive. We place a premium on the different talents, resources, and perspectives that different parties bring to the table, because when we work together we mitigate our weaknesses and we achieve more.</p>
          </div>
          <div className="idlgy-othrs-img6" data-aos="fade-left"></div>
        </section>
      </main>
      <Footer />
    </div>
  )
}

export default Ideology;