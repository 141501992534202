import React from 'react';
import { Carousel } from "react-bootstrap";
import airtel from '../assets/img/airtel.jpg';
import branch from '../assets/img/branch.png';
import choice from '../assets/img/choice.jpg';

const Testimonials = () => {

  return (
    <div className="review-carousel">
      <Carousel>
        <Carousel.Item>
          <div className="img-area">
            <img
              src={choice}
              alt="Multichoice"
            />
          </div>
          <Carousel.Caption>
            <p>"Outcess have been providing contact center solution to Multichoice Nigeria for over 5 years now and have delivered efficiently on expectation.<br /> Their professionalism, problem solving drive and attention to details can be attested to."</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="img-area">
            <img
              src={branch}
              alt="Branch"
            />
          </div>
          <Carousel.Caption>
            <p>"We confirm that Outcess is currently one of our debt collection and recovery partners and we have used their services for 2 years. We have found their services satisfactory and are pleased to recommend their services."</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="img-area">
            <img
              src={airtel}
              alt="Airtel"
            />
          </div>
          <Carousel.Caption>
            <p>"Outcess is one of our vendors providing conatct center, retail shop and back office outsourcing services to Airtel Networks limited and has been our vendor for over 7 years. <br />We have been very satisfied with their services."</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default Testimonials;