import outcess1 from '../assets/img/outcess-photo/outcess1.jpeg'
import outcess2 from '../assets/img/outcess-photo/outcess2.jpeg'
import outcess3 from '../assets/img/outcess-photo/outcess3.jpeg'
import outcess4 from '../assets/img/outcess-photo/outcess4.jpeg'
import outcess5 from '../assets/img/outcess-photo/outcess5.jpeg'
import outcess6 from '../assets/img/outcess-photo/outcess6.jpeg'
import outcess7 from '../assets/img/outcess-photo/outcess7.jpeg'
import outcess8 from '../assets/img/outcess-photo/outcess8.jpeg'
import outcess9 from '../assets/img/outcess-photo/outcess9.jpeg'
import outcess10 from '../assets/img/outcess-photo/outcess10.jpeg'
import outcess11 from '../assets/img/outcess-photo/outcess11.jpeg'
import outcess12 from '../assets/img/outcess-photo/outcess12.jpeg'
import outcess13 from '../assets/img/outcess-photo/outcess13.jpeg'
import outcess14 from '../assets/img/outcess-photo/outcess14.jpeg'
import outcess15 from '../assets/img/outcess-photo/outcess15.jpg'
import outcess16 from '../assets/img/outcess-photo/outcess16.jpeg'
import outcess17 from '../assets/img/outcess-photo/outcess17.jpeg'
import outcess18 from '../assets/img/outcess-photo/outcess18.jpeg'
import outcess19 from '../assets/img/outcess-photo/outcess19.jpeg'
import outcess20 from '../assets/img/outcess-photo/Abuja1.jpg'
import outcess21 from '../assets/img/outcess-photo/Abuja2.jpg'
import outcess22 from '../assets/img/outcess-photo/Abuja3.jpg'
import outcess23 from '../assets/img/outcess-photo/Abuja4.jpg'
import outcess24 from '../assets/img/outcess-photo/Abuja5.jpg'
import outcess25 from '../assets/img/outcess-photo/outcess21.jpeg'
import outcess26 from '../assets/img/outcess-photo/outcess22.jpeg'
import outcess27 from '../assets/img/outcess-photo/outcess23.jpeg'








export const IMAGES =
    [
        {
            src: outcess1,
            thumbnail: outcess1,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess2,
            thumbnail: outcess2,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess3,
            thumbnail: outcess3,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess4,
            thumbnail: outcess4,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess5,
            thumbnail: outcess5,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess6,
            thumbnail: outcess6,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess7,
            thumbnail: outcess7,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess8,
            thumbnail: outcess8,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess9,
            thumbnail: outcess9,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess10,
            thumbnail: outcess10,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess11,
            thumbnail: outcess11,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess12,
            thumbnail: outcess12,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess13,
            thumbnail: outcess13,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess14,
            thumbnail: outcess14,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess15,
            thumbnail: outcess15,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess16,
            thumbnail: outcess16,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess17,
            thumbnail: outcess17,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess18,
            thumbnail: outcess18,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess19,
            thumbnail: outcess19,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess20,
            thumbnail: outcess20,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess21,
            thumbnail: outcess21,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess22,
            thumbnail: outcess22,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess23,
            thumbnail: outcess23,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess24,
            thumbnail: outcess24,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess25,
            thumbnail: outcess25,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess26,
            thumbnail: outcess26,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
        {
            src: outcess27,
            thumbnail: outcess27,
            thumbnailWidth: 320,
            thumbnailHeight: 174,

        },
    ]
