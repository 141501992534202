import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RFQModal from "../components/RFQModal";
import { OmniData, Omni } from "../Library/Libraries";

const OmniChannelMarketing = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <div id="omni-channel-marketing">
      <Navbar />
      <div className="ocm-intro">
        <div className="ocm-intr-containr">
          <div
            className="ocm-intr-contnt"
            data-aos="fade-right"
            data-aos-delay="100">
            <h1>
              Omni-Channel <span>Marketing</span>
            </h1>
            <h4>
              Deliver seamless omni-channel experiences. <br />
              SmartMessage is the omni-channel marketing and customer experience
              platform that helps leading brands confidently scale
              communications over millions of customers in real-time.
            </h4>
            <button className="ccsnl-btn" onClick={handleShow}>
              Request for Consultation
            </button>
          </div>
        </div>
      </div>
      <div className="ocm-intro-sm">
        <div className="ocm-intr-containr">
          <div
            className="ocm-intr-contnt"
            data-aos="fade-left"
            data-aos-delay="100">
            <h3>
              SmartMessage <span>Marketing</span> Platform
            </h3>
            <h4>
              Available on-premise and in the cloud, SmartMessage brings all
              your marketing tools in a single, integrated platform, from
              managing content to automating your omnichannel customer journeys
              and measuring success.
            </h4>
          </div>
        </div>
      </div>

      <RFQModal show={show} setShow={setShow} />
      <main>
        <section>
          <h3>How SmartMessage works (TECHNOLOGY)</h3>
          {OmniData.map((data, index) => (
            <div key={index}>
              <div className="ocm-smartmsg-details">
                <h5 data-aos="fade-in" data-aos-delay="100">
                  {data.h4}
                </h5>
                <p data-aos="fade-out" data-aos-delay="500">
                  {data.p}
                </p>
                <p data-aos="fade-out" data-aos-delay="500">
                  {data.p1}
                </p>
                <p data-aos="fade-out" data-aos-delay="500">
                  {data.p2}
                </p>
                <p data-aos="fade-out" data-aos-delay="500">
                  {data.p3}
                </p>
              </div>
            </div>
          ))}
        </section>

        <section>
          {Omni.map((Omni, i) => (
            <div key={i}>
              <div className="ocm-othrs-card">
                <img
                  className="ocm-othrs-img"
                  data-aos="fade-in"
                  data-aos-delay="100"
                  src={Omni.img}
                  alt="Omni"
                  srcSet=""
                />
                <div
                  className="ocm-othrs-info"
                  data-aos="fade-out"
                  data-aos-delay="400">
                  <h4>{Omni.h42}</h4>
                  <p>{Omni.p}</p>
                  <p>{Omni.p1}</p>
                  <p>{Omni.p2}</p>
                  <p>{Omni.p3}</p>
                </div>
              </div>
            </div>
          ))}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default OmniChannelMarketing;
