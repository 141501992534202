import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import { IMAGES } from "../Library/photos";
import Gallery from 'react-grid-gallery';

const Gallary = () => {


  return (
    <div>
      <Navbar />
      <div className="gallery-page-img">
        <div className="gallery-page">
          <div className="gallery-page-text">

            <h1>Image <span id="openings-colour"> Gallery!</span></h1>
          </div>
        </div>
      </div>
      <main>
        <div data-aos="fade-out" data-aos-delay="400">

          <Gallery images={IMAGES} backdropClosesModal={true} className="gallery-img-backdrop" />

        </div>
      </main >
      <Footer />

    </div >
  )
}

export default Gallary
