import React from 'react';
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import { BPData } from "../Library/Libraries";

const BusinessPartners = () => {
  return (
    <div id="business-partners">
      <Navbar />
      <div className="biz-part-intro">
        <div className="biz-part-intro-container">
          <div className="biz-part-intro-content">
            <h1>Outcess <span>Business Partners</span></h1>
          </div>
        </div>
      </div>
      <main>
        {BPData.map((bp, i) =>
          <div key={i} className="biz-border ">
            <section  >
              <div className="biz-part-info " >
                <img className="biz-part-image" data-aos="fade-right" src={bp.img} alt="Outcess LTD" />
                <h4 data-aos="fade-left">{bp.h1} <span>{bp.h12}</span>{bp.h13}</h4>
              </div>
              <div className="biz-part-details" data-aos="fade-left">
                <h5>{bp.h2}:</h5>
                <p>{bp.p}</p>
              </div >
            </section >
          </div>)
        }
      </main>
      <Footer />
    </div>
  )
}

export default BusinessPartners;