import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Partners1 from "../assets/img/partners1.png";
import Partners2 from "../assets/img/partners2.png";
import Partners3 from "../assets/img/partners3.png";
import Partners4 from '../assets/img/partners4.png';
import Partners5 from '../assets/img/partners5.png';
import Partners6 from '../assets/img/partners6.png';

const OurPartners = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <Slider {...settings}>
      <div>
        <img src={Partners1} alt="Partners" />
      </div>
      <div>
        <img src={Partners2} alt="Partners" />
      </div>
      <div>
        <img src={Partners3} alt="Partners" />
      </div>
      <div>
        <img src={Partners4} alt="Partners" />
      </div>
      <div>
        <img src={Partners5} alt="Partners" />
      </div>
      <div>
        <img src={Partners6} alt="Partners" />
      </div>
    </Slider>
  )
}

export default OurPartners;