import React from 'react'
import Navbar from '../components/Navbar';
import { BsQuestionCircle } from "react-icons/bs";
import { faqData } from "../Library/Libraries";
import { faqHeadersData } from "../Library/Libraries";
import Footer from "../components/Footer";


const FAQs = () => {

  const data = (faqHeadersData)


  return (
    <div>
      <Navbar />
      <div className="faq-main-img">
        <div className="top-img-text">
          <div>

            <h1>Frequently Asked <span id="Question-colour" >Questions?</span></h1>
            <h4> {data[0].para3} </h4>
          </div>
        </div>
      </div>
      <main >
        <div id="contain-faqs" >
          <div data-aos="zoom-out-up">
            <h4 className="container-head-text">
              {data[0].header1} </h4>
            <h4>{data[0].para1}</h4>
            <h4>{data[0].para2}</h4>
          </div>
          <div >
            <h1 className="general-faqs-text" data-aos="zoom-in-right">
              {data[0].header2}<span id="Question-colour-2"> Questions</span> <BsQuestionCircle />
            </h1>
          </div>
          {faqData.map((faqs, i) => (
            <div key={i}>
              <div data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="500">
                <div className="faq-question" >
                  <input id={faqs.id} type="checkbox" className="panel" />
                  <div className="plus">+</div>
                  <label htmlFor={faqs.id} className="panel-title"> <h5> {faqs.header1}</h5></label>
                  <div className="panel-content">  <p>{faqs.text}</p></div>
                </div>

              </div>
            </div>
          ))}

        </div>
      </main>
      <Footer />
    </div>
  )
}

export default FAQs;