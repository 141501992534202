export const JOB_APPLICANTS_CREATE_REQUEST = 'JOB_APPLICANTS_CREATE_REQUEST';
export const JOB_APPLICANTS_CREATE_SUCCESS = 'JOB_APPLICANTS_CREATE_SUCCESS';
export const JOB_APPLICANTS_CREATE_FAIL = 'JOB_APPLICANTS_CREATE_FAIL';
export const JOB_APPLICANTS_CREATE_RESET = 'JOB_APPLICANTS_CREATE_RESET';


export const GET_ALL_JOB_POST_REQUEST = 'GET_ALL_JOB_POST_REQUEST';
export const GET_ALL_JOB_POST_SUCCESS = 'GET_ALL_JOB_POST_SUCCESS';
export const GET_ALL_JOB_POST_FAIL = 'GET_ALL_JOB_POST_FAIL';
export const GET_ALL_JOB_POST_RESET = 'GET_ALL_JOB_POST_RESET';


