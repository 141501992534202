import React, { useState } from 'react'; 
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import RFQModal from '../components/RFQModal'; 


const CloudSolutions = () => {
 
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true); 

 

  return (
    <div id="cloud-solutions">
      <Navbar /> 
      <div className="cld-intro">
        <div className="cld-intro-img" />
        <div className="cld-intro-cntnr">
          <div className="cld-intro-details" data-aos="fade-down">
            <div className="cld-intro-title">
              <h1>Cloud <span>Solutions</span></h1>
              <button className="ccsnl-btn cld-rfq-lg" onClick={handleShow}>Request for Consultation</button>
            </div>
            <div >
              <h3>Outcess is well versed in deploying a variety of  <span className="Crtical-colour">Critical Cloud Solution</span > platforms, including hybrid clouds that give you the best of both worlds.</h3>
              <button className="ccsnl-btn cld-rfq-sm">Request for Consultation</button>
            </div>
          </div>
        </div>
      </div>
      <RFQModal show={show} setShow={setShow} />
      <main>
        <div className="cld-sltns-cards">
          <div className="cld-card-move" data-aos="fade-in" data-aos-delay="100">
            <div className="cld-card-img1">
              <h5>Cloud Application Solution</h5>
            </div>
            <div className="cld-card-details">
              <p>A multi-channel customer experience strategy that delivers a seamless experience across all touchpoints and is easily available via an internet server.
                Benefits of Cloud Solution
                Speed of Deployment.
                Userfriendly systems.</p>
            </div>
          </div>
          <div className="cld-card-move" data-aos="fade-in" data-aos-delay="200">
            <div className="cld-card-img2">
              <h5>Cloud Management Solutions</h5>
            </div>
            <div className="cld-card-details">
              <p>Through our Cloud Management services offerings, we help
                define an optimal cloud operations framework that enables
                continuous delivery of development and operations across a
                combination of private, public or hybrid cloud models.</p>
            </div>
          </div>
          <div className="cld-card-move" data-aos="fade-in" data-aos-delay="300">
            <div className="cld-card-img3">
              <h5>The Virtual Advantage</h5>
            </div>
            <div className="cld-card-details">
              <p>Our Cloud Contact Center is a comprehensive suite of tools, applications and cloud-hosted solutions for contact centers in large organizations that require multiple communications channels (including phone calls and messaging), sophisticated call routing, agent management and analytics.</p>
            </div>
          </div>
          <div className="cld-card-move" data-aos="fade-in" data-aos-delay="400">
            <div className="cld-card-img4">
              <h5>Cloud Infrastructure Solutions</h5>
            </div>
            <div className="cld-card-details">
              <p>Our Cloud Infrastructure Solutions focus on planning, setting up and managing cloud environments with a portfolio of world-class solutions. They include hosted data center and public or private cloud platforms that host virtual data centers, multi-tenant enterprise services and virtual data desktops.
                Outcess offers dedicated, private cloud service hosted and managed on a per‑client basis. Each runs in a highly customized environment with enterprise‑class functionality designed to meet a wide range of customer requirements.</p>
            </div>
          </div>
          <div className="cld-card-move" data-aos="fade-in" data-aos-delay="500">
            <div className="cld-card-img5">
              <h5>Cloud Software Solutions</h5>
            </div>
            <div className="cld-card-details">
              <p>Outcess Cloud Software Solutions address a range of critical business processes in the Software-as- a-Services (SaaS) space. This includes customer relationship management, contact center and business productivity along with the supporting back-end processes. Our strategic relationship with major SaaS vendors—including Microsoft and Drishti —enable us to provide best in class execution.</p>
            </div>
          </div>
          <div className="cld-card-move" data-aos="fade-in" data-aos-delay="600">
            <div className="cld-card-img6">
              <h5>Cloud  Based Contact Center </h5>
            </div>
            <div className="cld-card-details">
              <p>
                WHY USE CLOUD BASED
                CONTACT CENTER?
                BENEFITS OF OUR CLOUD
                <ul className="cld-card-list">
                  <li>Speed of Deployment</li>
                  <li>Secured Data</li>
                  <li>360 degree</li>
                  <li> Customer</li>
                  <li> Support</li>
                  <li>User-friendly systems</li>
                  <li>Holistic integrations</li>
                  <li> Increased productivity</li>
                  <li>Monitoring Performance</li>
                  <li>We help drive cost and the efficiencies, enhance flexibity and ensure secuity and compliancefor mission-critical application.</li>
                </ul>

              </p>
            </div>
          </div>
        </div>
        <div className="cld-sltn-end">
          <div className="cld-end-details">
            <p>Drawing upon some of the best technologies and platforms from our cloud partners, we work with you to implement the best cloud strategy and platforms for your organization.
            </p>
            <p>Our cloud solutions enable you to:</p>
            <p>Accelerate time - to - market and increase profitability.
              Enhance utilization of your IT infrastructure.
              Improve the customer experience.
              Virtualize processes.
              Increase workforce collaboration.</p>
            <button className="ccsnl-btn" onClick={handleShow}>Request for Consultation</button>
          </div >
          <div className="cld-end-img  " data-aos="fade-left" data-aos-delay="100" />
        </div >
      </main >
      <Footer />
    </div >
  )
}

export default CloudSolutions;