import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { IoIosLaptop } from "react-icons/io";
import { IoPhonePortraitOutline } from "react-icons/io5";
import chat from "../assets/img/chat.jpeg";
import learning from "../assets/img/fromhome.jpeg";
import work2 from "../assets/img/fromhome1.jpeg";
import work3 from "../assets/img/work3.jpeg";
import RFQModal from "../components/RFQModal";
import { wfhData } from "../Library/Libraries";
import remotes from "../assets/video/work-from-home.mp4";
import RequestQuote from "../components/RequestQuote";

const BeWorkFromHomeReady = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Navbar />
      <div>
        <div id="BeWorkFrom-image">
          <div className="container-video">
            <video id="video" autoPlay loop muted playsinline="true">
              <source src={remotes} type="video/mp4" />
            </video>
          </div>
          <div className="BeWorkFrom-image-text">
            <h1 className="h1-move">
              GO LIVE WITH REMOTE CONTACT CENTER{" "}
              <span style={{ color: "#F95738" }}>TODAY </span>
              <p style={{ padding: "5px", fontSize: "20px" }}>
                Setup a Remote Work From Home Contact Center in 3 days.
              </p>
            </h1>
          </div>
        </div>
      </div>
      <main>
        <div className="agent-text-phone">
          <h1 data-aos="fade-down">
            AGENTS HAVE ONLY{" "}
            <span style={{ color: "#F95738" }}>SMARTPHONES? </span>
          </h1>

          {/* <!-- Start Section features --> */}
          <div className="features  ">
            {wfhData.map((data, i) => (
              <div key={i}>
                <div
                  className="card"
                  id="card-contains"
                  style={{ maxWidth: "23rem", height: "20rem" }}
                  data-aos="fade-down">
                  <div className="card-body feat">
                    <span
                      className="glyphicon glyphicon-tint"
                      data-aos="fade-up"
                      data-aos-delay="500">
                      {data.icon}
                    </span>
                    <h4>{data.h4}</h4>
                    <p> {data.textp}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <!-- End Section features --> */}

        <div className="work-home-row ">
          <div
            className="work-home-column"
            id="first-img-left"
            data-aos="fade-up-right"
            data-aos-duration="10000">
            <img className="fit-img-column" src={learning} alt="" srcset="" />
          </div>
          <div
            className="work-home-column"
            id="second-img-right"
            data-aos="fade-left"
            data-aos-duration="20000">
            <img className="fit-img-column" src={chat} alt="" srcset="" />
          </div>
          <div
            className="work-home-column"
            id="third-img-left"
            data-aos="fade-right"
            data-aos-duration="30000">
            <img className="fit-img-column" src={work2} alt="" srcset="" />
          </div>
          <div
            className="work-home-column"
            id="four-img-card"
            data-aos="fade-up-left"
            data-aos-duration="40000">
            <img className="fit-img-column" src={work3} alt="" srcset="" />
          </div>
        </div>
        <div id="work-request-main">
          <div className="arrowright">
            <div className="arrowrightAlt">
              <MdOutlineArrowRightAlt />
            </div>
            <div className="arrow-down">
              <h6>REQUEST FOR CONSULTATION</h6>
            </div>
          </div>
        </div>
        <>
          <RequestQuote />
        </>

        <div id="card-body-align">
          <div
            className="card mb-5 card-borders"
            style={{ width: "35rem" }}
            data-aos="fade-down-right">
            <div className="card-body">
              <h5 className="card-title-icons1 text-center">
                <IoIosLaptop />
              </h5>
              <h5 className="card-title text-center ">
                HAVE ACCESS TO ONLY LAPTOP
              </h5>
              <p className="card-text text-center">
                {" "}
                Manage Complete Contact Center Operations with a Feature-Rich
                Solution Let the agent handle customer service requests while
                being in the safety of their homes.Ameyo offers VPN access
                combined with WebRTC to ensure high conversation quality in a
                secure environment.Additionally, with Ameyo’s remote call center
                software, enjoy comprehensive reports, and live monitoring tools
                to enable supervisors to remotely monitor agent performance
                along with getting complete activity logs (calls, notes,
                dispositions, etc).
                <section style={{ color: "#F95738" }}>
                  Secure VPN Access | WebRTC for Call Quality | Extensive
                  Monitoring.
                </section>
              </p>
            </div>
          </div>

          <div
            className="card mb-5 card-borders"
            style={{ width: "35rem" }}
            data-aos="fade-down-left">
            <div className="card-body">
              <h5 className="card-title-icons2 text-center">
                <IoPhonePortraitOutline />
              </h5>
              <h5 className="card-title text-center ">
                AGENTS HAVE ONLY FEATURE PHONES & NO INTERNET ?
              </h5>
              <p className="card-text text-center">
                Enable Contact Center Interactions via an IVR/ Feature Phone Do
                not let the absence of smartphones, laptops or a stable internet
                connection come in the way of delivering quality customer
                service.Ameyo’s Phone Agent functionality enables contact center
                operations via an IVR.Agents get to have complete call
                functionality like click-to-dial, hold, mute, transfer,
                conference, etc on their feature phone itself while all the
                calls are recorded on the server for security and monitoring
                purposes which the supervisor can access at any time to track
                KPIs and SLAs with remote call center software.
                <section style={{ color: "#F95738" }}>
                  Portable Agent Workbench | 100% Call Monitoring | Secure
                  Customer Data.
                </section>
              </p>
            </div>
          </div>
        </div>

        <div data-aos="fade-up">
          <h2 className="home-ready-last-text">
            {" "}
            WORK FROM<span style={{ color: "#F95738" }}> HOME</span>{" "}
          </h2>
          <br />
          <p>
            Collaborate with your team faster and easier from the comfort of
            your homes with Microsoft teams.
          </p>
          <p>
            Currently struggling to take your contact center Remote? No Laptop,
            No WI-FI connectivity Contact us today and set up a Remote Contact
            Center in 3 days.
          </p>
          <button className="ccsnl-btn mt-3" onClick={handleShow}>
            Request for Consultation
          </button>
        </div>
        <RFQModal show={show} setShow={setShow} />
      </main>
      <Footer />
    </div>
  );
};

export default BeWorkFromHomeReady;
