import React from 'react'
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import { outcessClientsData } from "../Library/Libraries";

const OutcessClients = () => {

  return (
    <div>
      <Navbar />
      <div className="clients-img-main">
        <div className="clients-text-main">
          <div>
            <h1>Outcess<span id="Question-colour-2"> Clients</span></h1>
          </div>
        </div>
      </div>
      <main id="Outcess-Clients-main">
        <div className=" m-10" id="clients-container">
          {outcessClientsData?.map((img, i) => (
            <div key={i}>
              <div id="card-clients" style={{ width: "12rem", height: "160px", marginBottom: "50px", margin: "15px" }} >
                <img className="w-100 h-100 " src={img.img} alt="Outcess" data-aos="zoom-in-up" />
              </div>
            </div>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default OutcessClients