import { subNavData } from "../Library/Libraries";
import {
    CASE_STUDY_DATA_CREATE_REQUEST, CASE_STUDY_DATA_CREATE_SUCCESS,
    CASE_STUDY_DATA_CREATE_FAIL
} from '../constants/caseStudiesConstants'

export const caseStudyAction = () => async (dispatch) => {
    try {

        dispatch({
            type: CASE_STUDY_DATA_CREATE_REQUEST
        })


        const data = subNavData;


        dispatch({
            type: CASE_STUDY_DATA_CREATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CASE_STUDY_DATA_CREATE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message,
        })
    }
}
