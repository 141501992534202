import axios from 'axios'
import {
    JOB_APPLICANTS_CREATE_REQUEST, JOB_APPLICANTS_CREATE_SUCCESS, JOB_APPLICANTS_CREATE_FAIL, GET_ALL_JOB_POST_REQUEST, GET_ALL_JOB_POST_SUCCESS, GET_ALL_JOB_POST_FAIL
} from '../constants/jobApplicantsConstants'
import { baseUrl } from '../shared/baseUrl';

export const createJobApplication = (
    fullname,
    email,
    gender,
    age,
    phoneNumber,
    address,
    jobLocation,
    educationalQualification,
    nyscStatus,
    operatingWindow,
    yearsOfExperience,
    resumeURL,
    igboLanguage,
    hausaLanguage,
    yorubaLanguage,
    englishLanguage,
    frenchLanguage,
) => async (dispatch) => {
    try {
        dispatch({
            type: JOB_APPLICANTS_CREATE_REQUEST
        })


        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        await axios.post(
            baseUrl + '/api/v1/jobapplications', {
            fullname,
            email,
            gender,
            age,
            phoneNumber,
            address,
            jobLocation,
            educationalQualification,
            nyscStatus,
            operatingWindow,
            yearsOfExperience,
            resumeURL,
            igboLanguage,
            hausaLanguage,
            yorubaLanguage,
            englishLanguage,
            frenchLanguage,
        },
            config)


        dispatch({
            type: JOB_APPLICANTS_CREATE_SUCCESS,
            success: true,
        })

    } catch (error) {
        dispatch({
            type: JOB_APPLICANTS_CREATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }

}



export const getAllCareerPostAction = () => async (dispatch) => {
    try {

        dispatch({
            type: GET_ALL_JOB_POST_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        const { data } = await axios.get(
            baseUrl + `/api/v1/career`, config)

        dispatch({
            type: GET_ALL_JOB_POST_SUCCESS,
            payload: data

        })

    } catch (error) {
        dispatch({
            type: GET_ALL_JOB_POST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message,
        })
    }
}

