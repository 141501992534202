import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { REQUEST_QUOTE_RESET } from "../constants/requestQuoteConstants";
import { useDispatch, useSelector } from "react-redux";
import { createQuoteRequest } from "../actions/requestQuoteAction";
import emailjs from "emailjs-com";
import swal from "sweetalert";

const RFQModal = ({ show, setShow, history }) => {
  const dispatch = useDispatch();
  const form = useRef();

  const createQuote = useSelector((state) => state.createQuote);
  const {
    success: successRequest,
    loading: loadingRequest,
    error: errorRequest,
  } = createQuote;

  const [errorCreate1, seterrorCreate1] = useState(false);

  useEffect(() => {
    if (errorRequest) {
      seterrorCreate1(true);
    }
    setTimeout(() => {
      seterrorCreate1(false);
    }, 1000);
  }, [errorRequest]);

  if (successRequest) {
    swal({
      title: "Your Request have been submitted successfully!",
      icon: "success",
      button: "Close",
    });
  }

  if (errorCreate1) {
    swal({
      title: `${errorRequest}`,
      icon: "error",
      button: "Close",
    });
  }

  const handleClose = () => setShow(false);
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (successRequest === true) {
      setShow(false);
      dispatch({
        type: REQUEST_QUOTE_RESET,
      });
      setCompanyName("");
      setEmail("");
      setService("");
      setDescription("");
    }
  }, [dispatch, successRequest, history, setShow]);

  const submitHandler = (e) => {
    e.preventDefault(e);
    dispatch(createQuoteRequest(companyName, email, service, description));
    emailjs
      .sendForm(
        "service_czlj7hs",
        "template_sbym0r6",
        form.current,
        "LSzAkP5R6YerQQpXk"
      )
      .then(
        (result) => console.log(result.text),
        (error) => console.log(error.text)
      );
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="rfq-modal">
        <Modal.Header closeButton>
          <Modal.Title>Request for Consultation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={form} onSubmit={submitHandler}>
            <input
              type="text"
              placeholder="Company/Full Name"
              required
              name="from_name"
              id="from_name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email Address"
              required
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <select
              value={service}
              name="service"
              id="service"
              onChange={(e) => setService(e.target.value)}>
              <option>Please Select Service</option>
              {[
                "Business Process Outsourcing",
                "Business Productivity Solutions",
                "Cloud Solutions",
                "Customer Engagement Solutions",
                "Omni-Channel Digital Marketing",
                "Remote Contact Center",
                "I.T Services",
              ].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <textarea
              rows="5"
              placeholder="Please describe your need..."
              required
              name="message"
              id="message"
              value={description}
              onChange={(e) => setDescription(e.target.value)}></textarea>

            <div className="rfq-modal-btn">
              <button
                className="ccsnl-btn"
                disabled={loadingRequest && true}
                type="submit"
                value="Request">
                {loadingRequest ? "submiting Request..." : "Request Consultation"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RFQModal;
