import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import { subNavData } from "../Library/Libraries";
import { Card } from 'react-bootstrap';
import Footer from "../components/Footer";
import { NavLink } from 'react-router-dom';


const CaseStudies = () => {

  const [filter, setFilter] = useState([])
  const [result, setResult] = useState("");
  const [data, setData] = useState([])

  //Getting the unique values in the array from caseStudyData
  const categories = ['ALL', ...new Set(subNavData.map((item) => item.category))];

  const showInfo = (catagory) => {
    setResult(catagory)
  }


  useEffect(() => {
    const found = subNavData.filter(element => element.category === result);
    setData(found)
    if (result === "ALL") {
      setData(subNavData)
      setFilter(subNavData)
    }
  }, [result, filter])


  useEffect(() => {
    setData(subNavData)
  }, [])

  const onClick = (e) => {
    setResult(e.target.value);
  }


  return (
    <div>
      <Navbar />
      <div className="case-metting-img">
        <div className="case-metting">

          <div className="case-metting-text">
            <h1> Case <span id="Question-colour-2">Study</span></h1>
          </div>
        </div>
      </div>
      <main>



        <div className="case-categories">
          <p className="case-title">Solutions</p>
          {categories.map((catagory, i) =>
            <button className="btn-case" onClick={() => showInfo(catagory)} key={i}>{catagory} </button>)}
          <div className="card-container pt-5 col-12">
            {data.map((data) => (
              <div key={data.id}>
                {/* <!-- Cards --> */}
                <div  >
                  <div id="cards_landscape_wrap-2" >
                    <div className="card-container" >
                      <div style={{ maxWidth: '22rem' }} >
                        <div className="card-flyer categories-card card" data-aos="fade-up"
                          data-aos-delay="2000" >
                          <div className="text-box">
                            <div className="image-box">
                              {!data.img ?
                                <div id="loader2-1">

                                  <div className="loader2 loader-black2 loader-12"></div>
                                </div>
                                :
                                <img src={data.img} className="image-fit" alt="categories" />}

                            </div>
                            <div className="text-container" >
                              <Card.Body>
                                <Card.Title>{data.header}</Card.Title>
                                <Card.Title className="Title-card-two">{data.title}</Card.Title>
                                <Card.Text>
                                  {data.text}
                                </Card.Text>
                                <NavLink to={`/casestudiessubpages/${data.id}/details`} >
                                  <button variant="primary" className="btn-primary" onClick={onClick} style={{ backgroundColor: 'none' }}>Learn more</button>
                                </NavLink >
                              </Card.Body>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End-Card */}
              </div>
            ))}
          </div>
        </div>

      </main >
      <Footer />
    </div >
  )

}
export default CaseStudies;
