import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import CaseStudies from "./pages/CaseStudies";
import CaseStudiesSubPages from "./pages/CaseStudiesSubPages";
import RequestForQuote from "./pages/RequestForQuote";
import BeWorkFromHomeReady from "./pages/BeWorkFromHomeReady";
import FAQs from "./pages/FAQs";
import OutcessClients from "./pages/OutcessClients";
import About from "./pages/About";
import BusinessPartners from "./pages/BusinessPartners";
import Ideology from "./pages/Ideology";
import Careers from "./pages/Careers";
import CurrentOpenings from "./pages/CurrentOpenings";
import Gallery from "./pages/Gallery";
import CloudSolutions from "./pages/CloudSolutions";
import OmniChannelMarketing from "./pages/Omni-ChannelMarketing";
import BusinessProdSolution from "./pages/BusinessProdSolution";
import BusinessOutsource from "./pages/BusinessOutsource";
import CustomerEngmntSolution from "./pages/CustomerEngmntSolution";
import NotFound from "./pages/NotFound";
import IsoQualityStatement from "./pages/IsoQualityStatement";
import DataPolicy from "./pages/DataPolicy";

function App() {
  AOS.init({
    once: true,
    duration: 1000,
    easing: "ease",
  });

  return (
    <div className="App">
      <Router basename="/">
        <ScrollToTop />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/case-studies" component={CaseStudies} />
          <Route
            path="/casestudiessubpages/:id/details"
            component={CaseStudiesSubPages}
          />
          <Route path="/contact-us" component={RequestForQuote} />
          <Route
            path="/be-work-from-home-ready"
            component={BeWorkFromHomeReady}
          />
          <Route path="/faqs" component={FAQs} />
          <Route path="/outcess-clients" component={OutcessClients} />
          <Route path="/about" component={About} />
          <Route path="/business-partners" component={BusinessPartners} />
          <Route path="/ideology" component={Ideology} />
          <Route path="/careers" component={Careers} />
          <Route path="/cloud-solutions" component={CloudSolutions} />
          <Route
            path="/omni-channel-marketing"
            component={OmniChannelMarketing}
          />
          <Route path="/current-openings" component={CurrentOpenings} />
          <Route path="/gallary" component={Gallery} />
          <Route
            path="/business-productivity-solutions"
            component={BusinessProdSolution}
          />
          <Route
            path="/business-process-outsourcing"
            component={BusinessOutsource}
          />
          <Route
            path="/customer-engagement-Solutions"
            component={CustomerEngmntSolution}
          />
          <Route path="/IsoQualityStatement" component={IsoQualityStatement} />
          <Route path="/data-policy" component={DataPolicy} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
