import oil from "../assets/img/oil & gas.jpg";
import media from "../assets/img/media.jpg";
import telecom from "../assets/img/telecom.jpg";
import credit from "../assets/img/credit-card.jpg";
import work from "../assets/img/work.jpeg";
import cloud from "../assets/img/cloud.png";
// import money from '../assets/img/money.jpg';
import stock from "../assets/img/stock.jpg";
// import finance from '../assets/img/finance.jpg';
import service from "../assets/img/service.jpeg";
import industry from "../assets/img/industry.jpg";
import teleco from "../assets/img/teleco.jpg";
import usaid from "../assets/img/dustin.jpeg";
import towering from "../assets/img/towering.jpg";
import cash from "../assets/img/cash.jpg";
import commission from "../assets/img/commission.jpg";
import choice from "../assets/img/choice.jpg";
import vodacom from "../assets/img/vodacom.jpg";
import mtn from "../assets/img/mtn.png";
import mobile from "../assets/img/9mobile.jpg";
import hb from "../assets/img/hb.png";
// import gtbank from '../assets/img/gtBank.png';
import airtel from "../assets/img/airtel.jpg";
import fidelity from "../assets/img/fidelity.png";
import sterling from "../assets/img/sterling.png";
import billions from "../assets/img/billions.png";
import total from "../assets/img/total.png";
import cscs from "../assets/img/cscs.png";
import branch from "../assets/img/branch.png";
import ipnx from "../assets/img/ipnx.png";
import mutualbenefit from "../assets/img/mutual-benefit.jpg";
import sunKing from "../assets/img/sunKing.jpg";
import renMoney from "../assets/img/renMoney.jpg";
import asoSaving from "../assets/img/asoSaving.jpg";
import NIS from "../assets/img/NIS.jpg";
import flaim from "../assets/img/flaim.png";
import fairmoney from "../assets/img/fairmoney.png";
import custodian from "../assets/img/custodian.jpg";
import rescue from "../assets/img/rescue.jpg";
import Landwey from "../assets/img/Landwey.png";
import NLPC from "../assets/img/NLPC.png";
import mecure from "../assets/img/mecure.jpg";
import smile from "../assets/img/smile.jpg";
import fluterwave from "../assets/img/fluterwave.png";
import bagasoo from "../assets/img/bagasoo.png";
import wakanow from "../assets/img/wakanow.jpg";
import leadCapital from "../assets/img/leadCapital.jpg";
import CAT from "../assets/img/CAT.jpg";
import unifiedPayments from "../assets/img/unifiedPayments.jpg";
import cadbury from "../assets/img/cadbury.jpg";
import embassy from "../assets/img/christ-embassy.png";
import swift from "../assets/img/swift.jpg";
import access from "../assets/img/access-bank.jpg";
import ntel from "../assets/img/n-tel.png";
import jumia from "../assets/img/jumia.png";
import customer1 from "../assets/img/customer-satisfaction.jpeg";
import customer2 from "../assets/img/focus.jpeg";
import customer3 from "../assets/img/work-look.jpeg";
import customer4 from "../assets/img/talk.jpeg";
import Ameyo33 from "../assets/img/ameyo1.jpeg";
import uconnect from "../assets/img/uconnect.png";
import smartsms from "../assets/img/parts2.jpg";
import nexFortris from "../assets/img/nexFortris.png";
import microsoft from "../assets/img/Microsoft1.png";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { RiCustomerService2Line } from "react-icons/ri";
import { FaServer } from "react-icons/fa";

export const subNavData = [
  {
    id: 1,
    header: "BUSINESS PRODUCTIVITY SOLUTIONS",
    title: "Workforce Management",
    category: "BPO",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome",
    text1:
      ": Client had issues tracking employee performance and attendance. Also, they had problem with scheduling shifts. ",

    text2:
      ": We were able to identify the exact needs of the client.We deployed a workforce management application that was able to help the client in planning.",

    text3:
      ": client was able to forecast, schedule and track employees performance.In a period of 5 years, number of agents which was initially 950 increased to 1700.",
    // eslint-disable-next-line
    category: "Business Productivity Solutions",
    text: "A multi-channel customer experience strategy that delivers a seamless experience across all touchpoints and is easily available via an internet server.Benefits of Cems.",
    img: work,
  },

  {
    id: 2,
    header: "CUSTOMER ENGAGEMENT",
    title: "Financial Service Industry-I",
    category: "BPO",
    span1: "Business Outcome",
    span2: "Our Solution",
    span3: "Business Outcome",
    text1:
      ": Clients inability to track interaction history of customers across all branches.",

    text2: ": We provided the customer with CRM.",

    text3:
      ": The CRM was used to centralize customer interactions across all its branch networks nationwide.Business productivity increased by 80%",
    // eslint-disable-next-line
    category: "Customer Engagement",
    text: "Business Challenge: Clients inability to track interaction history of customers across all branches. Our Solution: We provided the customer with CRM.",
    img: towering,
  },

  {
    id: 12,
    header: "BPO",
    title: "Media",
    category: "BPO",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome",
    text1:
      ": Client was looking at increasing the scope of its outbound service for churn and retention purposes.",
    text2:
      ": We provided the client with Business Process Outsourcing services to drive retention and eliminate churn.",
    text3:
      ": Reduced cost per interaction for the customer by more than 20% and agent seats increased from 50 seats on the first year to 270 seats on the sixth year.Business Productivity and Business Revenue increased by 90%.One of the largest media company in Africa.",
    // eslint-disable-next-line
    category: "BPO",
    text: "Business Challenge: Client was looking at increasing the scope of its outbound service , We provided the client with Business Process Outsourcing services to drive retention and eliminate churn.",
    img: media,
  },
  {
    id: 13,
    header: "BPO",
    title: "Oil and Gas",
    category: "BPO",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome",
    text1: ": Client decided to have an inbound contact center.",
    text2:
      ": We provided an inbound Contact Center from our center using our agents.",
    text3:
      ": Agents were empowered to handle 80% of customers issues escalations.Customer engagement increased by 80%.",
    // eslint-disable-next-line
    category: "BPO",
    text: "Business Challenge: Client decided to have an inbound contact center.Our Solution: We provided an inbound Contact Center from our center using our agents.",
    img: oil,
  },

  {
    id: 3,
    header: "CUSTOMER ENGAGEMENT",
    title: "Stock Exchange",
    category: "CUSTOMER ENGAGEMENT",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome",
    text1:
      ": In ability to track history of walk in customers and customer who reaches out to them via all their channel of interaction.",
    text2:
      ": We provided the customer with a contact centre solution that assist their agents to log all customers queries.",
    text3:
      ": Improve productivity and track interaction, synched back- office and front office.This increased business revenue over the years by 80%.",
    // eslint-disable-next-line
    category: "Customer Engagement",
    text: "Business Challenge: In ability to track history of walk in customers and customer who reaches out to them via all their channel of interaction.",
    img: stock,
  },

  {
    id: 4,
    header: "CUSTOMER ENGAGEMENT",
    title: "Financial Service Industry-II",
    category: "Cloud Solutions",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome ",
    text1:
      ": The client wanted a contact center application to increase efficiency of its contact center agents.",
    text2:
      ": We provided the client with a contact centre solution that helped increase agent productivity and speed in resolution.",
    text3:
      ": Because of the efficiency of the system, contact center grew from having just 15 agents to over 1600 agents within 8 years.With at least 190 actively logged in at the same time.This robust system we deployed has helped the clients to transform their outbound interactions become fully equipped to assist the contact centre operations from being a cost generating centre to a profit centre.",
    // eslint-disable-next-line
    category: "Customer Engagement",
    text: "Business Challenge: The client wanted a contact center application to increase efficiency of its contact center agents.",
    img: cash,
  },

  {
    id: 5,
    header: "CUSTOMER ENGAGEMENT",
    title: "Financial Service Industry-III",
    category: "Customer Engagement",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome ",
    text1:
      ": The client wanted to personalize customer interactions through their contact center operations.",
    text2:
      ": We provided the customer with a customer relationship management tool to enhance customer experience across their various touch points.",
    text3:
      ": The Customer relationship management tool helped the agent to be able to track interaction histories of customers on all channels of engagement.Also, agents were able to address customer by their names when they engage the contact center.",
    // eslint-disable-next-line
    category: "Customer Engagement",
    text: "Business Challenge: The client wanted to personalize customer interactions through their contact center operations.",
    img: service,
  },

  {
    id: 6,
    header: "CUSTOMER ENGAGEMENT",
    title: "Financial Service Industry-IV",
    category: "Customer Engagement",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome ",
    text1:
      ": No centralised desk for managing customer contacts and engagement.",
    text2:
      ": Set up a fully fledged contact center which connects to their CRM and gives customers a choice of which to engage the bank from.",
    text3:
      ": Customer satisfaction went up by 55% and issue resolution went up 90%.Agent’s workload, business revenue, business productivity increased by 90%",
    // eslint-disable-next-line
    category: "Customer Engagement",
    text: "Set up a fully fledged contact center which connects to their CRM and gives customers a choice of which to engage the bank from.",
    img: industry,
  },

  {
    id: 7,
    header: "CUSTOMER ENGAGEMENT",
    title: "Telecommunications-I",
    category: "Customer Engagement",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome ",
    text1:
      ": They have over 1500 agents work per shift 24hours a day. Because of the large number of resources Supervisors had to work tediously to able to keep track of their resources as well schedule them for work daily.",
    text2: ": We deployed Workforce management tool for the contact centre.",
    text3:
      ": Supervisors are better able to identify their resources per shifts and forecast scheduling for up to 3months at a time.This improved their efficiency level tremendously on their daily functions.",
    // eslint-disable-next-line
    category: "BPO",
    text: "They have over 1500 agents work per shift 24hours a day. Because of the large number of resources Supervisors had to work tediously to able to keep track of their resources .",
    // eslint-disable-next-line
    category: "Customer Engagement",
    // eslint-disable-next-line
    text: "We worked with the customer and identified the right process and number of of outsourced seats to start with and were able to start engaging from our center within 3 weeks.",
    img: teleco,
  },

  {
    id: 8,
    header: "BPO",
    title: "Telecommunications-II",
    category: "Customer Engagement",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome ",
    text1:
      ": Clients in this sector wanted a significant reduction in cost of running contact center.",
    text2:
      ": We worked with the customer and identified the right process and number of of outsourced seats to start with and were able to start engaging from our center within 3 weeks.",
    text3:
      ": Achieved reduction in overhead cost by about 55% and reduced cost for human resources by 100%.Because of the initial success(we increased revenue by 90%) customer increased their outsource seats with Outcess from 13 to 225 over a 5 year period.",
    // eslint-disable-next-line
    category: "BPO",
    text: "They have over 1500 agents work per shift 24hours a day. Because of the large number of resources Supervisors had to work tediously to able to keep track of their resources .",

    img: telecom,
  },

  {
    id: 9,
    header: "CLOUD SOLUTIONS",
    title: "Insurance",
    category: "Insurance",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome ",
    text1:
      ": Have a hub where they will capture all customers data in one view without investing in infrastructure.",
    text2:
      ": Outcess provided connectivity for the clients to our cloud contact center.",
    text3:
      ": Our contact center increased their agent productivity by 100% as they can view all customer interactions on an omni channel platform.We also were able to give the customer the full experience of a contact center at a very affordable cost.",
    // eslint-disable-next-line
    category: "Cloud Solutions",
    text: "Business Challenge: Have a hub where they will capture all customers data in one view without investing in infrastructure.",
    img: cloud,
  },
  {
    id: 10,
    header: "CUSTOMER ENGAGEMENT",
    title: "Government Agency",
    category: "Customer Engagement",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome ",
    text1:
      ": Difficulty of Nigerians (especially those in Diaspora) to reach the government agency on information on how to get their documents renewed as well as get location of liaison offices abroad.",
    text2:
      ": Outcess deployed the contact centre solution to enable the government agency team take queries from Nigerians all over the world.",
    text3:
      ": This has improved turnaround time of Nigerians getting the right information on documents renewals as well as information regarding their services by over 200%.",
    // eslint-disable-next-line
    category: "Customer Engagement",
    text: "Business Challenge: Difficulty of Nigerians (especially those in Diaspora) to reach the government agency on information on how to get their documents.",
    img: usaid,
  },

  {
    id: 11,
    header: "BPO",
    title: "Fintech",
    category: "Debt Recovery",
    span1: "Business Challenge",
    span2: "Our Solution",
    span3: "Business Outcome ",
    text1:
      ": Customers of loan offering companies had issues with loan repayment.",
    text2: ": We deployed over 130 agents for debt recovery purposes.",
    text3:
      ": Through our various channels of recovery(SMS, Calls, Chats), they experienced growth in debt recovery by 168% in the first 7 months of operations. Agent seats increased from 46 to 65 agents.",
    // eslint-disable-next-line
    category: "BPO",
    text: "Debt Recovery: Customers of loan offering companies had issues with loan repayment.Our Solution: We deployed over 130 agents for debt recovery purposes.",
    img: credit,
  },
];

export const workData = [
  {
    text1:
      "Boost Customer Service with Industry’s First Enterprise-Grade Mobile Call Center Solution. Ameyo promises an enhanced customer experience for businesses with limited contact center infrastructure – The Ameyo Mobile Agent. An industry- first mobile application with enterprise - ready call center features. The application is powered by mobile WebRTC and ensures high call quality, access to customer details via CTI, dialers for wide outreach, faster deployment, remote agent monitoring and all this within a Mobile App. Zero Agent Infrastructure Needed | Enterprise - Grade Features | Comprehensive Remote Monitoring.",
  },
  {
    text2:
      "Manage Complete Contact Center Operations with a Feature-Rich Solution Let the agent handle customer service requests while being in the safety of their homes.Ameyo offers VPN access combined with WebRTC to ensure high conversation quality in a secure environment.Additionally, with Ameyo’s remote call center software, enjoy comprehensive reports, and live monitoring tools to enable supervisors to remotely monitor agent performance along with getting complete activity logs(calls, notes, dispositions, etc).Secure VPN Access | WebRTC for Call Quality | Extensive Monitoring",
  },
  {
    text3:
      "Enable Contact Center Interactions via an IVR/ Feature Phone Do not let the absence of smartphones, laptops or a stable internet connection come in the way of delivering quality customer service.Ameyo’s Phone Agent functionality enables contact center operations via an IVR.Agents get to have complete call functionality like click- to - dial, hold, mute, transfer, conference, etc on their feature phone itself while all the calls are recorded on the server for security and monitoring purposes which the supervisor can access at any time to track KPIs and SLAs with remote call center software. Portable Agent Workbench | 100 % Call Monitoring | Secure Customer Data.",
  },
];

export const faqHeadersData = [
  {
    header1: "We are here to answer all questions",
    para1:
      "Having issues or difficulties accessing or understanding our services?",
    para2: "Here are few questions that are recently asked.",
    header2: "General Frequently Asked ",
    para3:
      "We aim to help your business through providing services you need and also provide answers about our products & services.",
  },
];

export const faqData = [
  {
    id: 1,
    header1: "What services will you be providing?",
    text: "At Outcess we provide the following solutions: - Business Process Outsourcing and Customer Engagement - We deliver successful customer acquisition, retention and growth for our clients by leveraging technology and software solutions.",
  },
  {
    id: 2,
    header1: "How you intend to provide the customer satisfaction?",
    text: "Research has it that attracting a new customer costs 5 times more than keeping existing ones. We understand the benefits of satisfying current customers, which is why we strongly support Customer Engagement. We provide every means possible through which customers can reach out to companies and have their issues resolved at the best time possible.",
  },
  {
    id: 3,
    header1: "Does this service of yours also apply to small scale businesses?",
    text: "Our unique service spreads out to diverse businesses, regardless of the type, size or particular sector of the economy they fall into.",
  },
  {
    id: 4,
    header1: "How can we help your business?",
    text: "Through the collaboration with customers in discussing needs and demand, we attain mutual understanding, gain customer trust to offer appropriate advice, and bring about suggestions on suitable technology to transform your busines",
  },
  {
    id: 5,
    header1: "What are the advantages of Outcess?",
    text: "Outcess takes into consideration every little detail to make sure the system run smoothly and responsively. Outcess employs a new technique called Unified Technology for securing customers' database &amp; building up highly confidential firewalls.",
  },
];

export const outcessClientsData = [
  {
    img: commission,
  },
  {
    img: choice,
  },
  {
    img: vodacom,
  },
  {
    img: mtn,
  },
  {
    img: mobile,
  },
  {
    img: hb,
  },
  // {
  //     img: gtbank,
  // },
  {
    img: airtel,
  },
  {
    img: fidelity,
  },
  {
    img: sterling,
  },
  {
    img: billions,
  },
  {
    img: total,
  },
  {
    img: cscs,
  },
  {
    img: branch,
  },
  {
    img: ipnx,
  },
  {
    img: mutualbenefit,
  },
  {
    img: sunKing,
  },
  {
    img: renMoney,
  },
  {
    img: asoSaving,
  },
  {
    img: NIS,
  },
  {
    img: flaim,
  },
  {
    img: fairmoney,
  },
  {
    img: custodian,
  },
  {
    img: rescue,
  },
  {
    img: Landwey,
  },
  {
    img: NLPC,
  },
  {
    img: mecure,
  },
  {
    img: smile,
  },
  {
    img: fluterwave,
  },
  {
    img: bagasoo,
  },
  {
    img: wakanow,
  },
  {
    img: leadCapital,
  },
  {
    img: CAT,
  },
  {
    img: unifiedPayments,
  },
  {
    img: cadbury,
  },
  {
    img: embassy,
  },
  {
    img: swift,
  },
  {
    img: access,
  },
  {
    img: ntel,
  },
  {
    img: jumia,
  },
];

export const OmniData = [
  {
    h4: "Flexible and scalable platform",
    p: "SmartMessage supports on-premise, cloud or hybrid deployments. With this scalable platform, you’ll improve the total cost of ownership, deliver better results and be ready for future challenges.",
  },
  {
    h4: "Built-in house, not bought",
    p: "SmartMessage platform was built from the ground up to meet the evolving needs of businesses around the world. All the modules offered were built-in house and are natively integrated.",
  },
  {
    h4: "Extensive integration capabilities",
    p: "Elevate your organizational agility by using SmartMessage connectors and API’s to facilitate the integration of your existing business applications including legacy systems with SmartMessage Marketing Platform.",
  },
  {
    h4: "A unique maturity models",
    p: "Our flexible technology, modular services packages, and profile and channel-based pricing model allow you to start now and grow with SmartMessage as you expand your marketing maturity.Deliverability services If you are struggling with understanding what is affecting your inbox placement and ultimately the success of your emails, we have an inhouse team available to help you.",
  },
  {
    h4: "Deliverability Consultation",
    p: "– Sender Score Certification application process",
    p1: "– Sender Reputation Management – Continuous monitoring, support and monthly reporting services to ensure that your deliverability is managed as carefully as possible.",
  },
  {
    h4: "Support services",
    p: "Ongoing support for technology investments is critical, and needs vary from organization to organization. Our support team provides comprehensive support for companies of all sizes—from day- to-day incident resolution to operational support and system monitoring.",
    p1: "–Choose the support plan that’s right for you – Gold, Platinum, or Enterprise",
    p2: "– 24 / 7 dedicated hotline for critical troubleshooting is available upon request",
    p3: "– Fully managed services for campaign planning, execution and reporting services.",
  },
  {
    h4: "360 Degree Customer Profile",
    p: "Profiles are elastically searchable and available in the UI and as an API to be leveraged across the enterprise. Provide a single source of truth for all your customer-facing teams. ",
    p1: "–Using channels, devices , campaign responses , purchase activity , product interests and location to determine future customer behavior. ",
  },
  {
    h4: "Behavioral Clustering",
    p: "Empower customers to manage their own experience through self- service preference centers that enable them to manage their communication preferences under GDPR and other regional data protection regulations.",
  },
  {
    h4: "Data Collection & Unification",
    p: "Understand and speak to your customers as individuals, teams and organizations through a single unified customer profile. Outcess continuously collects data and signals from all sources, channels and devices including anonymous identifiers. This data is aggregated to create a complete, living unified customer profile that is essential to powering your marketing decisions and orchestration.",
  },
  {
    h4: "Deployment Services",
    p: "Our experienced, skilled team comprised of technical, strategy, product and project management experts will ensure your successful implementation and full use of our Marketing Platform.",
    p1: "– Deploy quickly and reliably anywhere in the world",
    p2: "– Accelerate time to value and minimize risk.",
    p3: "– Accelerate user adoption with onsite technical and end - user training.",
  },
  {
    h4: "Give Customers control",
    p: "Using channels, devices, responses, purchase activity, product interests and location to determine future customer behavior.",
  },
  {
    h4: "Data Collection & Unification",
    p: "Empower customers to manage their own experience.",
  },
];

export const Omni = [
  {
    img: customer1,
    h42: "Data Collection & Unification",
    p: "Understand and speak to your customers as individuals, teams and organizations through a single unified customer profile. Outcess continuously collects data and signals from all sources, channels and devices including anonymous identifiers. This data is aggregated to create a complete, living unified customer profile that is essential to powering your marketing decisions and orchestration.",
  },
  {
    img: customer2,
    h42: "Deployment Services",
    p: "Our experienced, skilled team comprised of technical, strategy, product and project management experts will ensure your successful implementation and full use of our Marketing Platform.",
    p1: "– Deploy quickly and reliably anywhere in the world",
    p2: "– Accelerate time to value and minimize risk.",
    p3: "– Accelerate user adoption with onsite technical and end - user training",
  },
  {
    img: customer3,
    h42: "Give Customers control",
    p: "Using channels, devices, responses, purchase activity, product interests and location to determine future customer behavior.",
  },
  {
    img: customer4,
    h42: "Data Collection & Unification",
    p: "Collect, manage and organize limitless quantities of customer data and signals from any source using multiple import methods including sFTP, real-time streaming, connectors, API’s, web forms and manual uploads.",
  },
];

export const BPData = [
  {
    img: microsoft,
    h1: "Microsoft Corporation was  ",
    h12: "  incorporated on ",
    h13: "  September 22, 1993.",
    h2: "Microsoft",
    p: "It is a technology company that develops licenses and supports a range of software products which include office 360 commercial such as Office Exchange, SharePoint, and Skype for Business and related Client Access Licenses (CALs), Outlook.com and OneDrive, and Dynamics business solutions, including Dynamics ERP products, Dynamics CRM on-premises, and Dynamics CRM Online, and other services and devices.",
  },
  {
    img: smartsms,
    h1: "SmartMessage was founded in 2005, a pioneer in the email marketing and",
    h12: " enterprise communication",
    h13: "  industry.",
    h2: "Smart Message",
    p: "Throughout our journey, we’ve explored the challenges that disabled our customers from realizing their full potential and the barriers that complicated their move towards digital transformation.",
  },
  {
    img: Ameyo33,
    h1: "Ameyo is a leading  ",
    h12: "  Omnichannel customer engagement  ",
    h13: "  technology company.",
    h2: "Ameyo",
    p: "Ameyo is an Omnichannel customer engagement platform that helps businesses go remote with its 3 Unique Work from Home Solutions and help them streamline their customer service, customer support, and collection processes. Ameyo’s robust platform is available for on-cloud and on-premise implementation with private, public, as well as hybrid instances. It has pre-built integrations with all significant industry-grade CRMs. Ameyo provides strong omnichannel capabilities of Voice, IVR, ACD, Dialers, Email, Chat, and Social Media such as Instagram, Google Play Store, Twitter, Facebook & WhatsApp.",
  },
  {
    img: nexFortris,
    h1: "NetFortris delivers secure, ",
    h12: "reliable communication solutions, ",
    h13: "customized to meet the unique needs of mid-market and enterprise customers.",
    h2: "NetFortris",
    p: "NetFortris cloud solutions, carried over our private, nationwide MPLS network, include SD WAN, Voice & UCaaS, Network and Data Security and 4G backup. Not only do we manage these services with, or on behalf of our customers, we consolidate billing to streamline their operation. Customized Solutions, Secure and Reliable, Geared toward Growth, that’s Business Communications Simplified. That’s NetFortris.",
  },
  {
    img: uconnect,
    h1: "Uconnect was founded in 2004, a  ",
    h12: "  Human resource consulting company  ",
    h13: "  with head office in Lekki peninsula, Lagos.",
    h2: "Uconnect",
    p: "We are a proud Nigerian operation offering local support and services in the area of Human resource solutions with service offerings which include world class personnel outsourcing, e-recruitment, head hunting, training and development, quality management solutions, outplacements, payroll administration, computer based pre-assessment and competency testing as well as Business process outsourcing.",
  },
];

export const wfhData = [
  {
    id: 1,
    icon: <RiCustomerService2Line />,
    h4: "Customer Service",
    textp:
      "Boost Customer Service with Industry’s First Enterprise-Grade Mobile Call Center Solution. Ameyo promises an enhanced customer experience for businesses with limited contact center infrastructure – The Ameyo Mobile Agent.",
  },
  {
    id: 2,
    icon: <IoPhonePortraitOutline />,
    h4: "Mobile Application",
    textp:
      "An industry- first mobile application with enterprise - ready call center features. The application is powered by mobile WebRTC and ensures high call quality, access to customer details via CTI, dialers for wide outreach, faster deployment, remote agent monitoring and all this within a Mobile App.",
  },
  {
    id: 3,
    icon: <FaServer />,
    h4: "Zero Infrastructure",
    textp:
      "Zero Agent Infrastructure Needed | Enterprise - Grade Features | Comprehensive Remote Monitoring. Currently struggling to take your contact center Remote? No Laptop, No WI-FI connectivity Contact us today and set up a Remote Contact Center in 3 days.",
  },
];
