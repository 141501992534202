import { REQUEST_QUOTE_REQUEST, REQUEST_QUOTE_SUCCESS, REQUEST_QUOTE_FAIL, REQUEST_QUOTE_RESET } from '../constants/requestQuoteConstants'


export const createQuoteReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_QUOTE_REQUEST:
            return { loading: true }
        case REQUEST_QUOTE_SUCCESS:
            return { loading: false, success: true }
        case REQUEST_QUOTE_FAIL:
            return { loading: false, error: action.payload }
        case REQUEST_QUOTE_RESET:
            return {}
        default:
            return state
    }
}