import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { subNavData } from "../Library/Libraries";
import ReactPaginate from "react-paginate";
import {
  BsGrid3X3Gap,
  BsArrowLeftCircle,
  BsArrowRightCircle,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { caseStudyAction } from "../actions/caseStudiesAction";
// import RFQModal from '../components/RFQModal';

const CaseStudiesSubPages = ({ history, match }) => {
  //  const [show, setShow] = useState(false);
  //  const handleShow = () => setShow(true);

  const caseId = match.params.id;
  const dispatch = useDispatch();

  const getById = useSelector((state) => state.caseStudys);
  const study = getById;
  const [result, setResult] = useState(caseId);
  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);

  const num = parseInt(caseId);

  useEffect(() => {
    if (!study || study.id !== caseId) {
      dispatch(caseStudyAction(caseId));
      setFilterd(subNavData);
    } else {
      setData(subNavData);
    }
  }, [history, dispatch, study, caseId]);

  useEffect(() => {
    setResult(caseId);
  }, [caseId]);

  useEffect(() => {
    const results = filtered?.filter((data) => data.id === num);
    setData(results);
  }, [result, filtered, num]);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 1;
  const pagesVisited = pageNumber * usersPerPage;
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const display = subNavData?.slice(pagesVisited, pagesVisited + usersPerPage);
  const pageCount = Math.ceil(subNavData?.length / usersPerPage);

  const [next, setNext] = useState(false);
  const onClick = () => {
    setNext(true);
  };

  return (
    <div>
      <Navbar />
      {next ? (
        <div>
          <div>
            {display.map((data) => (
              <div key={data.id}>
                <div className="m-4  subpages-main ">
                  <div
                    className="row g-0 subpages-card "
                    id="sub-card-fade-left"
                    data-aos="fade-left">
                    <div
                      className="col-md-6 "
                      id="img-rapper"
                      style={{
                        // borderRadius: "10px",
                        backgroundColor: "#e6e6e6",
                        marginRight: "20px",
                      }}>
                      <img
                        src={data.img}
                        className="card-img-top h-100 sub-image img-fluid"
                        alt="case studies"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="card-body">
                        <h5 className="card-title">{data.header}</h5>
                        <h5 className="card-title subpages-title-two">
                          {data.title}
                        </h5>
                        <p className="subpages-text-para">
                          {" "}
                          <span className="span-bold">{data.span1}</span>{" "}
                          {data.text1}
                        </p>
                        <p className="subpages-text-para">
                          <span className="span-bold">{data.span2} </span>
                          {data.text2}
                        </p>
                        <p className="subpages-text-para">
                          <span className="span-bold">{data.span3} </span>
                          {data.text3}
                        </p>
                        <p className="card-text-third">
                          <small className="text-muted">
                            <a href="tel:+2348139851589">
                              CLICK HERE TO SPEAK TO US
                            </a>
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className=" sub-home">
            <Link to={`/case-studies`} exact="true" className="">
              <button className="btn btn-light sub-btn-move ">
                <BsGrid3X3Gap
                  style={{ fontSize: "30px", backgroundColor: "white" }}
                />
              </button>
            </Link>
            <ReactPaginate
              previousLabel={<BsArrowLeftCircle />}
              nextLabel={<BsArrowRightCircle />}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
          <Footer />
        </div>
      ) : (
        <div>
          {data.map((data) => (
            <div key={data.id}>
              <div className="  m-4  subpages-main ">
                <div className="row g-0 subpages-card">
                  <div
                    className="col-md-6"
                    style={{ borderRadius: "10px", backgroundColor: "#e6e6e6" }}
                    id="img-rapper">
                    <img
                      src={data.img}
                      className="card-img-top h-100 "
                      alt="Case Studies"
                    />
                  </div>
                  <div className="col-md-6">
                    <div
                      className="card-body"
                      id="sub-card-fade-left"
                      data-aos="fade-left">
                      <h5 className="card-title">{data.header}</h5>
                      <h5 className="card-title subpages-title-two">
                        {data.title}
                      </h5>
                      <p className="subpages-text-para">
                        {" "}
                        <span className="span-bold">{data.span1}</span>{" "}
                        {data.text1}
                      </p>
                      <p className="subpages-text-para">
                        <span className="span-bold">{data.span2} </span>
                        {data.text2}
                      </p>
                      <p className="subpages-text-para">
                        <span className="span-bold">{data.span3} </span>
                        {data.text3}
                      </p>
                      <p className="card-text-third">
                        <small className="text-muted">
                          <a href="tel:+2348139851589">
                            CLICK HERE TO SPEAK TO US
                          </a>
                        </small>
                      </p>
                    </div>
                    {/* <RFQModal show={show} setShow={setShow} /> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="studies-next-prev ">
            <div>
              <button onClick={onClick}>
                <BsArrowLeftCircle
                  style={{
                    fontSize: "30px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                />
              </button>
            </div>
            <div>
              <Link to={`/case-studies`} exact="true" className="">
                <button>
                  <BsGrid3X3Gap
                    style={{
                      fontSize: "30px",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  />
                </button>
              </Link>
            </div>
            <div>
              <button onClick={onClick}>
                <BsArrowRightCircle
                  style={{
                    fontSize: "30px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                />
              </button>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};
export default CaseStudiesSubPages;
