import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/img/outcess-logo.png";

const Navbar = () => {
  const [displayNav, setDisplayNav] = useState(false);

  // -- Sub Menu States -- //
  const [company, setCompany] = useState(false);
  const [solutions, setSolutions] = useState(false);

  return (
    <nav id="navbar">
      <div id="logo">
        <NavLink to="/" exact>
          <img src={Logo} alt="Outcess LTD" />
        </NavLink>
      </div>
      <div className="bg-screen-nav">
        <ul className="bg-nav-ul">
          <li
            onMouseEnter={() => setCompany(true)}
            onMouseLeave={() => setCompany(false)}
          >
            <p className="bg-screen-nav-title">Company</p>
            <div
              className={
                company ? "bg-screen-sub-menu display" : "bg-screen-sub-menu"
              }
            >
              <ul>
                <li>
                  <NavLink to="/about" activeClassName="active-link" exact>
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/business-partners"
                    activeClassName="active-link"
                    exact
                  >
                    Business Partners
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ideology" activeClassName="active-link" exact>
                    Ideology
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/IsoQualityStatement"
                    activeClassName="active-link"
                    exact
                  >
                    ISO Quality Statement
                  </NavLink>
                </li> */}
               
                <li>
                  <NavLink
                    to="/outcess-clients"
                    activeClassName="active-link"
                    exact
                  >
                    Clients
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/gallary" activeClassName="active-link" exact>
                    Gallery
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/faqs" activeClassName="active-link" exact>
                    FAQ
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li
            onMouseEnter={() => setSolutions(true)}
            onMouseLeave={() => setSolutions(false)}
          >
            <p className="bg-screen-nav-title">Solutions</p>
            <div
              className={
                solutions ? "bg-screen-sub-menu display" : "bg-screen-sub-menu"
              }
            >
              <ul>
                <li>
                  <NavLink
                    to="/business-process-outsourcing"
                    activeClassName="active-link"
                    exact
                  >
                    Business Process Outsourcing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/business-productivity-solutions"
                    activeClassName="active-link"
                    exact
                  >
                    Business Productivity Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/cloud-solutions"
                    activeClassName="active-link"
                    exact
                  >
                    Cloud Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/customer-engagement-Solutions"
                    activeClassName="active-link"
                    exact
                  >
                    Customer Engagement Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/omni-channel-marketing"
                    activeClassName="active-link"
                    exact
                  >
                    Omni-Channel Digital Marketing
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <NavLink to="/case-studies" className="bg-screen-nav-title" exact>
              Case Studies
            </NavLink>
          </li>
          <li>
            <li>
              <NavLink to="/careers" activeClassName="active-link" exact>
                Careers
              </NavLink>
            </li>
          </li>
          <li>
            <NavLink to="/contact-us" className="bg-screen-nav-title" exact>
              REQUEST CONSULTATION
            </NavLink>
          </li>
        </ul>
        <NavLink
          to="/be-work-from-home-ready"
          activeClassName="active-link"
          exact
        >
          <button>Be remote ready</button>
        </NavLink>
      </div>
      <div className="sm-screen-nav">
        <div
          className={displayNav ? "menu-btn close" : "menu-btn"}
          onClick={() => setDisplayNav(!displayNav)}
        >
          <div className="btn-line" />
          <div className="btn-line" />
          <div className="btn-line" />
        </div>
        <div
          className={
            displayNav ? "menu-container display-menu" : "menu-container"
          }
        >
          <div className="menu-content">
            <NavLink
              to="/be-work-from-home-ready"
              activeClassName="active-link"
              exact
            >
              {" "}
              <button> Be remote ready</button>
            </NavLink>

            <div className="menu-grid">
              <div className="menu-grid-details">
                <p>Company</p>
                <ul>
                  <li>
                    <NavLink to="/about" activeClassName="active-link" exact>
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/business-partners"
                      activeClassName="active-link"
                      exact
                    >
                      Business Partners
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/ideology" activeClassName="active-link" exact>
                      Ideology
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/IsoQualityStatement"
                      activeClassName="active-link"
                      exact
                    >
                      ISO Quality Statement
                    </NavLink>
                  </li>
                 
                  <li>
                    <NavLink
                      to="/outcess-clients"
                      activeClassName="active-link"
                      exact
                    >
                      Clients
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/careers" activeClassName="active-link" exact>
                      Careers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/gallary" activeClassName="active-link" exact>
                      Gallery
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="menu-grid-details">
                <p>Case Study</p>
                <ul>
                  <li>
                    <NavLink
                      to="/case-studies"
                      className="bg-screen-nav-title"
                      exact
                    >
                      Categories
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="menu-grid-details">
              <p>Solutions</p>
              <ul>
                <li>
                  <NavLink
                    to="/business-process-outsourcing"
                    activeClassName="active-link"
                    exact
                  >
                    Business Process Outsourcing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/business-productivity-solutions"
                    activeClassName="active-link"
                    exact
                  >
                    Business Productivity Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/cloud-solutions"
                    activeClassName="active-link"
                    exact
                  >
                    Cloud Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/customer-engagement-Solutions"
                    activeClassName="active-link"
                    exact
                  >
                    Customer Engagement Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/omni-channel-marketing"
                    activeClassName="active-link"
                    exact
                  >
                    Omni-Channel Digital Marketing
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="menu-supports">
              <ul>
                <li>
                  <NavLink to="/contact-us" activeClassName="active-link" exact>
                    Request for Consultation
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us" activeClassName="active-link" exact>
                    Contact Us
                  </NavLink>
                  {/* <a >Contact Us</a> */}
                </li>
                <li>
                  <a href="https://outcessblog.lll-ll.com/">Blog</a>
                </li>
                <li>
                  <NavLink to="/faqs" activeClassName="active-link" exact>
                    FAQ
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
