import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { caseStudysReducer } from '../reducers/caseStudiesReducer'
import { createJobApplicationReducer, getAllJobPostReducer } from '../reducers/jobApplicantsReducer';
import { createQuoteReducer } from '../reducers/requestQuoteReducer';


const reducer = combineReducers({
    caseStudys: caseStudysReducer,
    createJobApp: createJobApplicationReducer,
    getAllJob: getAllJobPostReducer,
    createQuote: createQuoteReducer,


})

const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

const initialState = {
    user: { Info: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store