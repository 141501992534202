import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import signature from "../assets/img/signature.jpg";

const IsoQualityStatement = () => {
  return (
    <div id="iso-quality">
      <Navbar />
      <div className="iso-quality-intro">
        <div className="iso-quality-intro-container">
          <div className="iso-quality-intro-content">
            <h1>
              <span>ISO 90001 2015 </span>OUTCESS QUALITY STATEMENT
            </h1>
          </div>
        </div>
      </div>

      <main>
        <section className="about-iso">
          <div className="about-iso-img1" data-aos="fade-right"></div>
          <div className="about-iso-content" data-aos="fade-left">
            <h4>
              <span>WHAT IS </span>ISO 9001 2015?
            </h4>
            <p>
              An ISO 9001 Quality Management System is a consistent way of doing
              things which ensures that your products or services meet your
              customers’ needs. Improving your Quality Management System (QMS)
              to the level required by the ISO, strengthens your business
              performance; helping to protect it from whatever challenges lie
              ahead. It also helps to increase your market share and profit
              potential.It also helps to increase your market share and profit
              potential.
            </p>
          </div>
										
        </section>
        <section className="quality-policy-statement">
          <h4>
            <span>OUTCESS </span>QUALITY POLICY STATEMENT
          </h4>
          <p>
            At Outcess Solutions, we are committed to delivering exceptional customer experiences through our Service Delivery and Call Center / Contact Center operations. 
            We strive to provide the highest level of service, meet customer needs, and exceed their expectations.
            Our Quality Policy outlines our commitment to quality and continuous improvement in every aspect of our operations. </p>
            <ol>
              <li>
              <span>Customer Focus: </span>We prioritize our customers by understanding their requirements, providing timely and accurate information,
                and resolving their queries and concerns efficiently.We aim to build strong relationships and deliver
                a personalized and professional experience at every interaction.
              </li>
             
              <li>
                <span>Continuous Improvement: </span> We are committed to the monitoring and reviewing our Quality
                Management System and processes through annual audits,
                management reviews, and customer satisfaction surveys, to ensure
                that they are relevant and effective to changing needs of our
                customers.
              </li>
              <li>
                <span>Excellence in Communication: </span>
               We value effective and clear communication with our customers. Our agents are trained to actively listen, 
               communicate professionally, and provide accurate information,
                ensuring that our customers receive the assistance they need promptly and courteously.
              </li>
              <li>
                <span>Performance Monitoring and Measurement: </span>
                We employ robust monitoring and measurement mechanisms to assess the performance of our operations. 
                Through regular quality assessments, call monitoring, and performance metrics, we track our performance 
                against set targets and strive for continuous improvement.
              </li>
              <li>
                <span>Training and Development: </span>
               We invest in the development and training of our employees to equip them with the necessary skills, knowledge, and tools to deliver exceptional service. 
               We foster a learning culture that encourages continuous professional growth and provides opportunities for career advancement.
              </li>
              <li>
                <span>Compliance and Data Security: </span>
                We adhere to all applicable laws, regulations, and industry standards to ensure data security, privacy, and confidentiality. We maintain strict 
                compliance with relevant guidelines to safeguard customer information and maintain the integrity
                 of our operations.pportunities for career advancement.
              </li>
              <li>
                <span>Teamwork and Collaboration:</span>
                We promote a culture of teamwork and collaboration, 
               fostering a positive work environment that encourages cooperation, knowledge sharing, and mutual support among our employees. We believe that
                working together as a team leads to better outcomes and customer satisfaction.
              </li>
            </ol>
          <p>This Quality Policy sets the foundation for our Call Center / Contact Center operations and serves as a guide for all employees. 
            We are dedicated to upholding these principles, meeting our quality objectives,
             and continuously improving our services to provide an outstanding customer experience.</p>
         
          {/* <div className="date-and-signature">
            <div className="signature">
              <img src={signature} alt=""></img>
              <p>IKENNA ODIKE</p>
              <p>CHIEF EXECUTIVE OFFICER</p>
            </div>
            <div className="date">
              <p>____ 23-08-2022 ____</p>
              <p style={{textAlign:"center"}}>DATE</p>
            </div>
          </div> */}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default IsoQualityStatement;
